import React from 'react';

import './App.css';

import Logo from './Logo.js';


import airbnbSVG from '../src/assets/architectures/airbnb.svg';
import isleSVG from '../src/assets/architectures/isle.svg';
import languageSVG from '../src/assets/architectures/airbnb.svg';


import airbnbMP4 from '../src/assets/demos/airbnb.mp4';
import isleMP4 from '../src/assets/demos/isle.mp4';
import languageMP4 from '../src/assets/demos/language.mp4';
import frontendcardsMP4 from '../src/assets/demos/frontend-cards.mp4';


// -----------------------------------
//             HEADER
// -----------------------------------


function Header(props: any) {
  return (
    <header>
      <div className="controls">
        <b>thedatadev<span>.com</span></b>
        {/* <label>
          <input type="checkbox" onChange={() => {
            props.toggleLanguage();
          }}/>
          <span>English</span>
          <span>日本語</span>
          <div />
        </label> */}
        <ul className="contacts">
          <li>
            <a target="_blank" rel="noreferrer" href="https://github.com/thedatadev" title="View my GitHub profile!">
              <i className="fab fa-github"></i>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/the_data_dev" title="View my Twitter profile!">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/edrian-gomez-3a7958188/" title="View my Linkedin profile!">
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
        <label>
          <input type="checkbox" onChange={() => {
            props.toggleTheme();
          }}/>
          <span>Light</span>
          <span>Dark</span>
          <div />
        </label>
      </div>
    </header>
  );
}


// -----------------------------------
//             PROFILE
// -----------------------------------

type ProfileState = {
  currentTaglineIndex: number;
};
type ProfileProps = {
  lang: 'jp' | 'en';
};
class Profile extends React.Component<ProfileProps, ProfileState> {

  taglines: any;
  intervalID: any;
  initialUpdateDone: boolean;
  intl: any;

  constructor(props: any) {
    super(props);

    this.taglines = [
      {
        en: ` eat Tonkotsu ramen 🍜`,
        jp: `豚骨ラーメンを食べるのがすきです。`,
      },
      {
        en: ` shoot fadeaway jump shots 🏀`,
        jp: `日本語`,
      },
      {
        en: ` bust a move 🕺🏽`,
        jp: `ダンスが好きです。`,
      },
      {
        en: ` watch Japanese game shows 📺`,
        jp: `日本のお笑いが好きです。`,
      },
      {
        en: ` travel abroad ✈️`,
        jp: `海外旅行が好きです。`,
      },
      {
        en: ` get sh*t done 👔`,
        jp: `仕事をするのが好きです。`,
      },
      {
        en: ` spend 3 hours at the character customisation screen 🎮`,
        jp: `３時間`,
      },
      {
        en: ` solve important problems 🔎`,
        jp: `大切な問題を解決するのが好きです。`,
      },
      {
        en: ` listen to city pop 🎧`,
        jp: `City Popの音楽を聞くのが好きです。`,
      },
      {
        en: ` explore Daiso 🏪`,
        jp: `DAISO`,
      },
      {
        en: ` get creative 🎨`,
        jp: `Get creative`,
      },
    ];

    this.intl = {
      hello: {
        en: `Hello, I'm`,
        jp: `こんにちは、`,
      },
      name: {
        en: `Edrian Gomez.`,
        jp: `エドリアン・ゴメズです。`,
      },
      selfIntro:{
        en: `A software creative & ML engineer based in Sydney who likes to`,
        jp: `シドニーに住んでいるソフトウェア・クリエイティブでマシーンラーニングのエンジニアです。`,
      },
    };

    this.state = {
      currentTaglineIndex: 0,
    };

    this.intervalID = null;

    this.initialUpdateDone = false;
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState((prevState) => ({
        currentTaglineIndex: (prevState.currentTaglineIndex + 1) % this.taglines.length
      }), () => {
        this.initialUpdateDone = true;
      });
    }, 3000);
  }


  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const setAnimateClass = (index: number): string => {
      const prevActiveIndex = this.state.currentTaglineIndex === 0
        ? this.taglines.length - 1
        : this.state.currentTaglineIndex - 1;

      let classList = '';
      if (index === this.state.currentTaglineIndex) {
        classList = 'should-slideup';
      } else if (index === prevActiveIndex) {
        classList = 'should-slidedown'
      }

      return classList;
    };

    const {lang} = this.props;

    return (
      <section className="profile">
        <div className="logo">
          <Logo />
        </div>
        <div className="about">
          <h1>
            <div>{this.intl.hello[lang]}</div>
            <div>{this.intl.name[lang]}</div>
          </h1>
          <p>
            {this.intl.selfIntro[lang]}
            <span className="tagline-container">
              {this.taglines.map(
                (tagline: any, index: number) =>
                  <b className={setAnimateClass(index)}>{tagline[lang]}</b>
                )
              }
            </span>
          </p>
        </div>
      </section>
    )
  }
}


// -----------------------------------
//             PORTFOLIO
// -----------------------------------


type PortfolioState = {
  activeProject: ProjectType | null;
};
type PortfolioProps = {
  lang: 'jp' | 'en';
};
class Portfolio extends React.Component<PortfolioProps, PortfolioState> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeProject: null,
    };
  }

  render() {
    const projects = [
      {
        name: {
          en: `Frontend cards`,
          jp: ``,
        },
        summary: {
          en: <p>
            {`Learn how to develop websites by first dissecting UIs into smaller components. For example, a typical eCommerce website could contain a header, catalog, and a footer. Furthermore, the header could contain a home button, a search bar, and a set of navigation links. This project aims to `}
            <b>{`help beginners learn web-development using a bottom-up approach `}</b>
            {`i.e. by learning how to implement the smallest components in a UI first.`}<span className='link-cta'>{'Read more'}</span>
          </p>,
          jp: ``,
        },
        description: [
          {
            en: <p>
              <b>{`Typical user story. `}</b>
              {`The user browses a catalog of UIs and picks one that interests them. They are then taken to a screen that shows a break-down of what subcomponents the chosen UI is comprised of. If we visualise this break-down as a tree of components, the user starts at the leaf nodes and eventually develops the techniques necessary to implement the chosen UI as a whole i.e. once they reach the "root node."`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Technical challenges. `}</b>
              {`Each UI on the website needs to have screenshots for all its components, its component's components, its component's component's components... and so on. To automate this process I set a "data-" attribute on every component I want a screenshot for, and run a custom TestCafe script to take PNG screenshots of every component across various screen sizes. Once the user starts learning how to code a particular component, they are shown a simple text editor (based on the Monaco editor) as well as a preview panel. The preview panel shows a realtime preview of their code implementation. Once they are happy with their implementation, they can evaluate their work by running a visual regression operation (based on pixelmatch) that compares actual and expected screenshots.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Benefits of this approach. `}</b>
              {`There is a lot of value in presenting learning material in bite-sized chunks. It makes learning less intimidating, it's easier to stay motivated and there is less friction to actually start coding if the goal is small and won't take long. Furthermore, the web is saturated with videos and articles for learning web development which lends itself to a lot of passive learning. Coding is a practical discipline, and this project has a large emphasis on being hands-on.`}
            </p>,
            jp: ``,
          },
        ],
        demo: frontendcardsMP4,
        link: 'https://frontend.cards',
        externalLink: <a className="external" href="https://frontend.cards" target="_blank" rel="noreferrer">
          Visit the website
          <i className="fas fa-external-link-alt"></i>
        </a>
      },
      {
        name: {
          en: 'Language learning recommender',
          jp: ``,
        },
        summary: {
          en: <p>
            {`Language learning is a costly activity, both in terms of time and money. This project aims to `}
            <b>{`provide language learners an efficient way to find online source material `}</b>
            {`written in their target language. The goal is to use machine learning models to classify online documents and videos into predefined comprehension levels.`}<span className='link-cta'>{'Read more'}</span>
          </p>,
          jp: ``,
        },
        description: [
          {
            en: <p>
              <b>{`Emulating immersion. `}</b>
              {`The most effective way to learn a language is to immerse yourself in that culture, which people commonly do by studying abroad. However, not everyone has the means to do this. One alternative is to expose one's self to online articles and videos, but these online resources require different levels of comprehension and language proficiency. This project involves creating a recommender system that presents learners with articles and videos suited to their language level.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Using machine learning. `}</b>
              {`Natural language processing is used in a couple of ways. It's firstly used in an unsupervised learning setting in order to perform data exploration to uncover "latent language levels" within a dataset i.e. finding clusters of articles that should fall under the same language level. It's also used in a supervised learning setting whereby an established langiage proficiency grading system e.g. JLPT is used to classify articles into levels N1 to N5.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Datasets. `}</b>
              {`I've decided to start off with datasets containing Japanese texts, just because I myself am self-studying Japanese. Using Pushshift, I am pulling data from a subreddit that posts links to NHK Web Easy articles. These articles are simplified Japanese news articles targeted at Japanese youth and Japanese language learners. Each regular news article on NHK has a corresponding simplified version on NHK Web Easy. I pull both versions, and index them using a "Trie" data structure for retrieval purposes. I am currently trying to find a suitable video-based dataset which I plan to convert to text and index in the same way I do with the NHK articles.`}
            </p>,
            jp: ``,
          },
        ],
        repo: 'https://github.com/thedatadev/language-study-aid',
        architecture: languageSVG,
        demo: languageMP4,
      },
      {
        name: {
          en: 'isleVR',
          jp: ``,
        },
        summary: {
          en: <p>
            {`A mobile browser-based editor which allows you to drag and drop 3D models to create your own VR spaces. Each space is a small, self-contained context, hence the name 'isle'. It is also an acronym which stands for 'immersive secondary language education'. My aim is to build a `}
            <b>{`'better flash card system' for learning languages through immersion. `}</b>
            {`Features in the works include voice-capture for spoken practice and cross-platform, multi-player interaction.`}<span className='link-cta'>{'Read more'}</span>
          </p>,
          jp: ``,
        },
        description: [
          {
            en: <p>
              <b>{`Contextual-based learning. `}</b>
              {`Vocabulary and expressions are better learned in context. Trying to memorize a thousand words using flash cards doesn't allow one to understand relations between words and the concepts behind them.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Virtual reality. `}</b>
              {`Through virtual reality, we can create a controlled learning environment that can be modelled to simulate various situations and environments that occur in real life. In this VR space, a learner can interact with objects or NPCs in the language they are learning.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Implementation. `}</b>
              {`The current MVP is implemented with three.js and only works on mobile devices and browsers that support Web XR. The 3D models shown in the demo are basic assets I created using Blender. Currently, there are smartphone devices that have the ability to perform photogrammetry which is something I plan to explore (since creating my own assets isn't scalable). Ideally, I would like to re-build this project using Unity since it also has an option to export C# projects as a web app using WebAssembly.`}
            </p>,
            jp: ``,
          },
        ],
        repo: 'https://github.com/thedatadev/isle',
        architecture: isleSVG,
        demo: isleMP4,
        shouldRotate: true,
      },
      {
        name: {
          en: 'B&B Chatbot',
          jp: ``,
        },
        summary: {
          en: <p>
            {`Replaces traditional forms with a conversational interface to facilitate search queries. Demonstrates the strengths of current state-of-the-art chatbot technology. This is contrasted with traditional web search forms which are structured differently across all sites. Instead, `}
            <b>{`having a consistent interface across all sites improves user experience `}</b>
            {`through free-form queries making it faster for users to find what they need.`}<span className='link-cta'>{'Read more'}</span>
          </p>,
          jp: ``,
        },
        description: [
          {
            en: <p>
              <b>{`Rationale. `}</b>
              {`Traditional search forms vary from webpage to webpage and come in different structures. What if we could standardize the way search forms are implemented to make them webpage-agnostic whilst improving user experience? People are already used to using messenger apps, so by taking a familiar UI design we reduce friction and cognitive load involved in filling out forms.`}
            </p>,
            jp: ``,
          },
          {
            en: <p>
              <b>{`Implementation. `}</b>
              {`Search queries are sent to Dialogflow which uses domain knowledge to parse a query and return the query's parameters (e.g. price, location, length of stay) in a structured format using named-entity recognition. These parameters are then used to query a relational database and the query results are filtered, sorted and scored by a custom recommender system built on Python and Pandas. These results are displayed on a simple web client built on Vue.js and Vuex.`}
            </p>,
            jp: ``,
          },
        ],
        repo: 'https://github.com/thedatadev/lorenzo-reuploaded',
        architecture: airbnbSVG,
        demo: airbnbMP4,
      },
    ];
  
    return (
      <section className="portfolio">
      {projects.map((project) =>(
        <Project lang={this.props.lang} project={project}/>
      ))}
    </section>
    )
  }
}

type ProjectType = {
  name: any
  summary: any;
  description: Array<any>;
  repo?: string;
  link?: string;
  architecture?: any;
  demo?: any;
  shouldRotate?: boolean;
  externalLink?: any;
}

type ProjectState = {
  showPlayBtn: boolean;
};
type ProjectProps = {
  project: ProjectType;
  lang: 'jp' | 'en';
};
class Project extends React.Component<ProjectProps, ProjectState> {

  videoRef: any;
  playRef: any;
  contentRef: any;
  detailsRef: any;

  constructor(props: any) {
    super(props);

    this.videoRef = React.createRef();
    this.playRef = React.createRef();
    this.contentRef = React.createRef();
    this.detailsRef = React.createRef();

    this.state = {
      showPlayBtn: true,
    };

    this.scrollToSeeContent = this.scrollToSeeContent.bind(this);
  }

  scrollToSeeContent() {
    const content = this.contentRef.current;
    const details = this.detailsRef.current;
    if (content && details) {
      const wasOpened = !details.hasAttribute('open');
      if (wasOpened) {
        setTimeout(() => {
          content.scrollIntoView({behavior: "smooth", block: "start"});
        }, 100);
      }
    }
  }

  componentDidMount() {
    const playBtn = this.playRef.current;
    if (playBtn) {
      playBtn.addEventListener('click', () => {
        const video = this.videoRef.current;
        if (video) {
          video.play();
          video.addEventListener('pause', () => {
            this.setState({showPlayBtn: true});
          });
          video.addEventListener('ended', () => {
            this.setState({showPlayBtn: true});
          });
          this.setState({showPlayBtn: false});
        }
      });
    }    
  }

  render() {
    const {lang, project} = this.props;
    const {showPlayBtn} = this.state;

    return (
      <details className='project-card' ref={this.detailsRef}>
        <summary className="summary" onClick={this.scrollToSeeContent}>
          <h2>{project.name[lang]}</h2>
          {project.summary[lang]}
          {project.repo && <a className="card-link" href={project.repo} target="_blank" rel="noreferrer">
            <i className="fab fa-github"></i>
          </a>}
          {project.link && <a className="card-link" href={project.link} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i>
          </a>}
          <div className="glimmer"/>
        </summary>
        {/* TODO - rename content to project-content */}
        <div className="content" ref={this.contentRef}>
          {project.link && <a className="demo-link" href={project.link} target="_blank" rel="noreferrer">
            {'View a live demo'}
            <i className="fas fa-external-link-alt"></i>
          </a>}

          <div className="preview">
            {project.demo && <>
              <video ref={this.videoRef} className="portrait preview-media" data-should-rotate={project.shouldRotate} src={project.demo} />
              {<div aria-hidden={!showPlayBtn} ref={this.playRef} className="play" id="playBtn">
                <i className="far fa-play-circle"></i>
              </div>}
              </>}
            {!project.demo && <div className="placeholder">Coming soon</div>}
          </div>
  
          {project.description.map((text) => text[lang])}
        </div>
      </details>
    );
  }
}

type AppProps = object;
type AppState = {
  showSecondaryLanguage: boolean,
};
export default class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showSecondaryLanguage: false,
    };

    this.toggleTheme = this.toggleTheme.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  toggleTheme() {
    const currentTheme = document.body.getAttribute('data-theme') || 'light';
    const newTheme = currentTheme === 'light'
      ? 'dark'
      : 'light';
    document.body.setAttribute('data-theme', newTheme);
  }

  toggleLanguage() {
    this.setState((prevState) => ({
      showSecondaryLanguage: !prevState.showSecondaryLanguage,
    }));
  }

  render() {
    const lang = this.state.showSecondaryLanguage ? 'jp' : 'en';
    return (
      <div data-lang={lang} id="app">
        <Header toggleTheme={this.toggleTheme} toggleLanguage={this.toggleLanguage}/>
  
        <main>
          <Profile lang={lang}/>
          <Portfolio lang={lang}/>
        </main>
      </div>
    );
  }
}