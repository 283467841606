export default function Logo() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 500 500">
            <g className="TH">
                <path className="T1" d="M151.744,237.867l-2.426,0c-0.211,-0.844 -0.593,-1.846 -1.145,-3.006c-0.552,-1.16 -1.168,-2.256 -1.85,-3.287c-0.704,-1.078 -1.474,-2.021 -2.308,-2.83c-0.834,-0.808 -1.638,-1.271 -2.413,-1.389c-0.728,-0.093 -1.645,-0.164 -2.749,-0.21c-1.104,-0.047 -2.126,-0.071 -3.065,-0.071l-2.044,0l0,39.903c0,0.773 0.164,1.482 0.492,2.127c0.328,0.644 0.903,1.142 1.723,1.494c0.422,0.164 1.307,0.386 2.654,0.668c1.348,0.281 2.397,0.433 3.147,0.457l-0,2.496l-23.555,-0l0,-2.496c0.656,-0.047 1.658,-0.141 3.006,-0.282c1.348,-0.14 2.279,-0.316 2.795,-0.527c0.773,-0.305 1.342,-0.744 1.705,-1.318c0.363,-0.575 0.545,-1.342 0.545,-2.303l-0,-40.219l-2.039,0c-0.727,0 -1.629,0.018 -2.707,0.053c-1.078,0.035 -2.11,0.111 -3.094,0.228c-0.773,0.094 -1.576,0.557 -2.408,1.389c-0.832,0.832 -1.6,1.776 -2.303,2.83c-0.703,1.055 -1.324,2.174 -1.863,3.358c-0.539,1.183 -0.914,2.162 -1.125,2.935l-2.461,0l-0,-13.535l43.488,0l0,13.535Z"/>
                <path d="M210,274.219l-20.707,-0l-0,-2.496c0.562,-0.047 1.377,-0.118 2.443,-0.211c1.067,-0.094 1.799,-0.235 2.198,-0.422c0.703,-0.281 1.218,-0.709 1.546,-1.283c0.329,-0.575 0.493,-1.319 0.493,-2.233l-0,-17.754l-23.45,0l0,17.368c0,0.82 0.135,1.511 0.405,2.074c0.269,0.562 0.802,1.054 1.599,1.476c0.399,0.211 1.108,0.422 2.127,0.633c1.02,0.211 1.823,0.328 2.408,0.352l0,2.496l-20.707,-0l0,-2.496c0.563,-0.047 1.377,-0.118 2.444,-0.211c1.066,-0.094 1.799,-0.235 2.197,-0.422c0.703,-0.281 1.219,-0.709 1.547,-1.283c0.328,-0.575 0.492,-1.319 0.492,-2.233l0,-36c0,-0.75 -0.152,-1.435 -0.457,-2.056c-0.305,-0.622 -0.832,-1.108 -1.582,-1.459c-0.633,-0.305 -1.4,-0.575 -2.303,-0.809c-0.902,-0.234 -1.681,-0.375 -2.338,-0.422l0,-2.496l20.707,0l0,2.496c-0.656,0.024 -1.458,0.129 -2.408,0.317c-0.949,0.187 -1.658,0.363 -2.127,0.527c-0.797,0.281 -1.33,0.75 -1.599,1.406c-0.27,0.656 -0.405,1.371 -0.405,2.145l0,15.468l23.45,0l-0,-15.117c-0,-0.75 -0.153,-1.435 -0.457,-2.056c-0.305,-0.622 -0.832,-1.108 -1.582,-1.459c-0.633,-0.305 -1.401,-0.575 -2.303,-0.809c-0.902,-0.234 -1.682,-0.375 -2.338,-0.422l-0,-2.496l20.707,0l-0,2.496c-0.656,0.024 -1.459,0.129 -2.408,0.317c-0.949,0.187 -1.658,0.363 -2.127,0.527c-0.797,0.281 -1.33,0.75 -1.6,1.406c-0.269,0.656 -0.404,1.371 -0.404,2.145l-0,35.965c-0,0.82 0.135,1.511 0.404,2.074c0.27,0.562 0.803,1.054 1.6,1.476c0.398,0.211 1.107,0.422 2.127,0.633c1.019,0.211 1.822,0.328 2.408,0.352l-0,2.496Z"/>
                </g><g className="ATA"><path className="T2" d="M341.744,173.535l-2.426,0c-0.211,-0.844 -0.593,-1.846 -1.145,-3.006c-0.552,-1.16 -1.168,-2.256 -1.85,-3.287c-0.704,-1.078 -1.474,-2.021 -2.308,-2.83c-0.834,-0.808 -1.638,-1.271 -2.413,-1.389c-0.728,-0.093 -1.645,-0.164 -2.749,-0.21c-1.104,-0.047 -2.126,-0.071 -3.065,-0.071l-2.044,0l0,39.903c0,0.773 0.164,1.482 0.492,2.126c0.328,0.645 0.903,1.143 1.723,1.495c0.422,0.164 1.307,0.386 2.654,0.668c1.348,0.281 2.397,0.433 3.147,0.457l-0,2.496l-23.555,-0l0,-2.496c0.656,-0.047 1.658,-0.141 3.006,-0.282c1.348,-0.14 2.279,-0.316 2.795,-0.527c0.773,-0.305 1.342,-0.744 1.705,-1.318c0.363,-0.575 0.545,-1.342 0.545,-2.303l-0,-40.219l-2.039,0c-0.727,0 -1.629,0.018 -2.707,0.053c-1.078,0.035 -2.11,0.111 -3.094,0.228c-0.773,0.094 -1.576,0.557 -2.408,1.389c-0.832,0.832 -1.6,1.776 -2.303,2.83c-0.703,1.055 -1.324,2.174 -1.863,3.358c-0.539,1.183 -0.914,2.162 -1.125,2.935l-2.461,0l-0,-13.535l43.488,0l0,13.535Z"/>
                <path className="λ" d="M274.115,162.855c-0.749,-0.07 -1.61,-0.105 -2.581,-0.105c-0.972,0 -1.656,-0.012 -2.054,-0.035c-1.17,-0.047 -2.072,-0.475 -2.704,-1.283c-0.632,-0.809 -0.948,-1.647 -0.948,-2.514c0,-1.055 0.41,-2.004 1.231,-2.848c0.82,-0.843 1.992,-1.265 3.515,-1.265c1.899,-0 3.516,0.597 4.852,1.793c1.336,1.195 2.484,3.187 3.445,5.976c1.078,3.094 2.203,6.24 3.375,9.44c1.172,3.199 2.309,6.369 3.41,9.509c1.383,3.868 2.678,7.506 3.885,10.916c1.207,3.411 2.256,6.206 3.147,8.385c0.937,2.297 1.728,3.803 2.373,4.518c0.644,0.715 1.763,1.072 3.357,1.072l1.582,0l0,2.25c-1.171,0.352 -2.219,0.662 -3.144,0.932c-0.925,0.269 -1.914,0.404 -2.968,0.404c-0.984,-0 -1.786,-0.188 -2.406,-0.563c-0.621,-0.375 -1.166,-0.914 -1.634,-1.617c-0.444,-0.679 -0.831,-1.535 -1.159,-2.566c-0.328,-1.031 -0.632,-2.016 -0.912,-2.953c-0.584,-1.946 -1.303,-4.635 -2.156,-8.069c-0.852,-3.433 -1.956,-7.433 -3.312,-12c-1.593,3.113 -3.38,6.809 -5.359,11.086c-1.979,4.278 -3.578,7.659 -4.796,10.143c-0.602,1.219 -1.047,2.262 -1.336,3.129c-0.29,0.867 -0.585,1.769 -0.886,2.707l-8.041,-0l17.196,-34.745c-0.799,-2.712 -1.593,-4.97 -2.38,-6.775c-0.788,-1.804 -1.652,-3.445 -2.592,-4.922Z"/>
                <path className="A" d="M391.398,210.59l-20.736,-0l0,-2.496c1.828,-0.117 3.346,-0.352 4.553,-0.703c1.207,-0.352 1.81,-0.786 1.81,-1.301c0,-0.211 -0.023,-0.469 -0.07,-0.774c-0.047,-0.304 -0.117,-0.574 -0.211,-0.808l-4.132,-11.18l-17.511,0c-0.657,1.641 -1.191,3.07 -1.602,4.289c-0.411,1.219 -0.781,2.344 -1.109,3.375c-0.305,1.008 -0.516,1.828 -0.634,2.461c-0.117,0.633 -0.176,1.149 -0.176,1.547c0,0.937 0.739,1.664 2.215,2.18c1.477,0.515 3.141,0.82 4.993,0.914l-0,2.496l-18.788,-0l0,-2.496c0.609,-0.047 1.371,-0.182 2.285,-0.405c0.914,-0.222 1.664,-0.521 2.25,-0.896c0.938,-0.633 1.664,-1.295 2.18,-1.986c0.515,-0.692 1.019,-1.647 1.512,-2.866c2.507,-6.257 5.273,-13.289 8.296,-21.093c3.024,-7.805 5.719,-14.754 8.086,-20.848l2.813,0l16.629,43.031c0.351,0.914 0.75,1.653 1.195,2.215c0.445,0.563 1.067,1.113 1.863,1.652c0.539,0.329 1.243,0.604 2.11,0.827c0.867,0.222 1.594,0.345 2.179,0.369l0,2.496Zm-20.07,-20.391l-7.556,-19.359l-7.449,19.359l15.005,0Z"/>
                </g><g className="DEV"><path d="M260,258.645l-1.652,15.574l-39.727,-0l0,-1.231l20.063,-22.346l-19.5,-25.079l-0,-1.231l37.617,0l-0,13.254l-2.496,-0c-0.516,-2.344 -1.506,-4.553 -2.971,-6.627c-1.465,-2.074 -2.982,-3.24 -4.553,-3.498c-0.703,-0.117 -1.705,-0.205 -3.006,-0.264c-1.3,-0.058 -2.361,-0.088 -3.181,-0.088l-10.855,0l15.636,20.11l0,0.562l-18.281,20.25l22.957,0c1.054,0 1.992,-0.316 2.812,-0.949c0.821,-0.633 1.524,-1.441 2.11,-2.426c0.539,-0.89 1.019,-1.881 1.441,-2.97c0.422,-1.09 0.797,-2.104 1.125,-3.041l2.461,-0Z"/>
                <path className="D" d="M263.959,185.355c-0,4.243 -0.797,7.922 -2.391,11.04c-1.593,3.117 -3.668,5.671 -6.222,7.664c-2.578,1.992 -5.473,3.48 -8.684,4.464c-3.211,0.985 -6.527,1.477 -9.949,1.477l-20.672,0l0,-2.496c0.68,-0 1.564,-0.065 2.654,-0.193c1.09,-0.129 1.811,-0.276 2.162,-0.44c0.704,-0.305 1.213,-0.756 1.53,-1.353c0.316,-0.598 0.474,-1.319 0.474,-2.163l0,-36c0,-0.796 -0.134,-1.511 -0.404,-2.144c-0.269,-0.633 -0.803,-1.137 -1.6,-1.512c-0.726,-0.351 -1.511,-0.609 -2.355,-0.773c-0.844,-0.164 -1.57,-0.27 -2.18,-0.317l0,-2.496l21.797,0c3,0 5.953,0.463 8.86,1.389c2.906,0.926 5.437,2.174 7.593,3.744c2.93,2.086 5.227,4.817 6.891,8.192c1.664,3.375 2.496,7.347 2.496,11.917Zm-8.613,-0.017c-0,-3.349 -0.446,-6.376 -1.336,-9.081c-0.891,-2.705 -2.192,-5.064 -3.903,-7.078c-1.617,-1.921 -3.603,-3.431 -5.959,-4.532c-2.355,-1.101 -4.974,-1.651 -7.857,-1.651c-0.961,0 -2.045,0.018 -3.252,0.053c-1.207,0.035 -2.103,0.064 -2.689,0.088l-0,38.324c-0,2.132 0.621,3.607 1.863,4.427c1.242,0.819 3.152,1.229 5.73,1.229c2.977,0 5.578,-0.527 7.805,-1.58c2.227,-1.054 4.031,-2.518 5.414,-4.391c1.453,-1.968 2.514,-4.245 3.182,-6.833c0.668,-2.588 1.002,-5.58 1.002,-8.975Z"/>
                <path className="V" d="M265.4,291.977c-0.609,0.023 -1.347,0.158 -2.214,0.404c-0.868,0.246 -1.582,0.545 -2.145,0.896c-0.797,0.493 -1.436,1.125 -1.916,1.899c-0.48,0.773 -0.955,1.687 -1.424,2.742c-1.57,3.727 -3.926,9.533 -7.066,17.42c-3.141,7.887 -6.375,16.107 -9.703,24.662l-2.918,-0c-3.375,-9.07 -6.633,-17.813 -9.774,-26.227c-3.14,-8.414 -5.273,-14.097 -6.398,-17.05c-0.281,-0.727 -0.656,-1.366 -1.125,-1.916c-0.469,-0.551 -1.09,-1.073 -1.863,-1.565c-0.633,-0.398 -1.336,-0.697 -2.11,-0.896c-0.773,-0.2 -1.488,-0.323 -2.144,-0.369l-0,-2.497l20.643,0l-0,2.497c-1.758,0.093 -3.24,0.269 -4.448,0.527c-1.207,0.258 -1.81,0.715 -1.81,1.371c-0,0.258 0.046,0.563 0.14,0.914c0.094,0.352 0.176,0.633 0.246,0.844c0.868,2.297 2.274,6.043 4.219,11.238c1.945,5.196 4.711,12.59 8.297,22.184c1.781,-4.339 3.832,-9.464 6.153,-15.375c2.32,-5.911 3.879,-9.969 4.676,-12.174c0.773,-2.134 1.224,-3.611 1.353,-4.432c0.129,-0.82 0.193,-1.488 0.193,-2.004c0,-0.539 -0.24,-0.99 -0.72,-1.353c-0.481,-0.363 -1.119,-0.674 -1.916,-0.932c-0.774,-0.234 -1.559,-0.41 -2.356,-0.527c-0.797,-0.117 -1.605,-0.211 -2.426,-0.281l0,-2.497l18.556,0l0,2.497Z"/>
            </g>
            <g id="Outer-Circle-1" className="outer-circle-1" transform-origin="250 250">
                <path id="Outer-Circle-1-Q1" className="outer-circle-1-q1" d="M260,80.204c86.274,4.227 155.569,73.522 159.796,159.796"/>
                <path id="Outer-Circle-1-Q2" className="outer-circle-1-q2" d="M419.603,260c-5.799,84.743 -74.426,152.341 -159.603,156.514"/>
                <path id="Outer-Circle-1-Q3" className="outer-circle-1-q3" d="M240,416.321c-83.652,-5.724 -150.597,-72.669 -156.321,-156.321"/>
                <path id="Outer-Circle-1-Q4" className="outer-circle-1-q4" d="M83.486,240c4.173,-85.177 71.771,-153.804 156.514,-159.603"/>
            </g>
            <g id="Outer-Circle-2" className="outer-circle-2" transform-origin="250 250">
                <path d="M59.835,245.956l-17.157,-0.878l0.175,-3.418l12.356,-3.439c1.15,-0.317 2.011,-0.547 2.582,-0.689c-0.615,-0.227 -1.516,-0.579 -2.704,-1.054l-11.727,-4.719l0.156,-3.054l17.157,0.877l-0.112,2.189l-14.36,-0.735l14.105,5.721l-0.105,2.048l-14.859,4.215l14.605,0.747l-0.112,2.189Z"/>
                <path d="M60.857,227.768l-16.049,-9.006l0.354,-2.424l18.014,-4.48l-0.374,2.563l-5.437,1.233l-1.036,7.108l4.876,2.617l-0.348,2.389Zm-6.266,-5.922l0.84,-5.764l-4.967,1.088c-1.509,0.333 -2.742,0.564 -3.701,0.693c1.059,0.375 2.097,0.838 3.114,1.389l4.714,2.594Z"/>
                <path d="M60.52,196.791l1.082,-2.081c1.707,0.894 2.895,2.056 3.564,3.487c0.669,1.431 0.798,3.017 0.386,4.758c-0.427,1.802 -1.14,3.18 -2.141,4.136c-1.001,0.955 -2.266,1.563 -3.797,1.823c-1.531,0.26 -3.094,0.201 -4.691,-0.177c-1.741,-0.412 -3.181,-1.104 -4.32,-2.076c-1.139,-0.972 -1.899,-2.152 -2.281,-3.539c-0.383,-1.387 -0.399,-2.818 -0.05,-4.292c0.396,-1.673 1.154,-2.979 2.276,-3.918c1.121,-0.938 2.506,-1.45 4.156,-1.533l-0.003,2.3c-1.308,0.1 -2.327,0.453 -3.056,1.059c-0.73,0.606 -1.226,1.468 -1.491,2.585c-0.304,1.285 -0.25,2.432 0.161,3.44c0.412,1.009 1.095,1.811 2.05,2.406c0.955,0.595 1.984,1.024 3.086,1.284c1.422,0.337 2.712,0.423 3.871,0.26c1.158,-0.163 2.103,-0.62 2.835,-1.37c0.731,-0.75 1.218,-1.635 1.459,-2.654c0.293,-1.239 0.184,-2.373 -0.327,-3.401c-0.512,-1.028 -1.435,-1.86 -2.769,-2.497Z"/>
                <path d="M67.737,193.185l-16.293,-5.449l0.721,-2.156l6.691,2.237l2.832,-8.468l-6.691,-2.238l0.721,-2.156l16.293,5.449l-0.721,2.156l-7.68,-2.568l-2.832,8.468l7.68,2.568l-0.721,2.157Z"/>
                <path d="M73.626,176.548l-15.917,-6.466l0.856,-2.106l15.916,6.465l-0.855,2.107Z"/>
                <path d="M75.93,170.739l-15.485,-7.441l1.01,-2.102l16.066,-2.291l-12.158,-5.842l0.944,-1.965l15.485,7.441l-1.01,2.102l-16.076,2.286l12.168,5.847l-0.944,1.965Z"/>
                <path d="M83.639,155.13l-14.763,-8.785l6.352,-10.675l1.742,1.037l-5.189,8.721l4.521,2.69l4.86,-8.167l1.732,1.031l-4.859,8.167l5.025,2.99l5.393,-9.063l1.742,1.037l-6.556,11.017Z"/>
                <path d="M95.672,136.083l-13.695,-10.373l1.373,-1.812l12.078,9.149l5.109,-6.745l1.616,1.224l-6.481,8.557Z"/>
                <path d="M103.876,125.359l-12.877,-11.372l8.223,-9.311l1.519,1.342l-6.717,7.607l3.944,3.483l6.291,-7.124l1.511,1.334l-6.292,7.124l4.384,3.871l6.981,-7.906l1.52,1.342l-8.487,9.61Z"/>
                <path d="M113.173,114.808l-7.162,-16.952l1.765,-1.699l16.98,7.5l-1.866,1.796l-5.052,-2.359l-5.175,4.983l2.249,5.056l-1.739,1.675Zm-1.321,-8.52l4.195,-4.04l-4.591,-2.184c-1.396,-0.662 -2.513,-1.236 -3.349,-1.721c0.607,0.945 1.143,1.947 1.609,3.006l2.136,4.939Z"/>
                <path d="M126.163,102.447l-10.752,-13.399l5.941,-4.767c1.194,-0.959 2.199,-1.567 3.013,-1.825c0.815,-0.258 1.653,-0.232 2.513,0.08c0.861,0.311 1.587,0.835 2.179,1.572c0.762,0.951 1.098,1.999 1.005,3.145c-0.092,1.145 -0.71,2.322 -1.854,3.531c0.65,-0.151 1.185,-0.215 1.605,-0.191c0.899,0.06 1.867,0.26 2.902,0.601l5.257,1.777l-2.23,1.789l-4.01,-1.364c-1.164,-0.389 -2.084,-0.662 -2.761,-0.82c-0.678,-0.157 -1.218,-0.215 -1.62,-0.173c-0.401,0.042 -0.766,0.14 -1.094,0.293c-0.236,0.118 -0.582,0.361 -1.039,0.728l-2.056,1.65l4.774,5.95l-1.773,1.423Zm-4.234,-8.908l3.812,-3.059c0.81,-0.65 1.377,-1.242 1.699,-1.777c0.323,-0.534 0.455,-1.081 0.397,-1.64c-0.059,-0.56 -0.261,-1.055 -0.608,-1.488c-0.509,-0.634 -1.157,-0.97 -1.945,-1.009c-0.787,-0.039 -1.678,0.34 -2.671,1.137l-4.241,3.403l3.557,4.433Z"/>
                <path d="M139.72,91.729l-9.572,-14.267l1.937,-1.299l15.008,6.174l-7.515,-11.201l1.81,-1.215l9.572,14.267l-1.937,1.299l-15.015,-6.183l7.522,11.21l-1.81,1.215Z"/>
                <path d="M154.641,82.092l-8.654,-14.841l1.964,-1.145l8.654,14.841l-1.964,1.145Z"/>
                <path d="M160.02,78.896l-7.719,-15.348l2.084,-1.048l14.121,7.996l-6.06,-12.05l1.947,-0.98l7.719,15.348l-2.083,1.048l-14.127,-8.006l6.066,12.061l-1.948,0.979Z"/>
                <path d="M180.652,62.024l-0.733,-1.877l6.776,-2.657l2.317,5.939c-0.717,1.236 -1.547,2.279 -2.49,3.129c-0.943,0.85 -1.997,1.503 -3.161,1.957c-1.572,0.613 -3.132,0.834 -4.679,0.662c-1.548,-0.172 -2.897,-0.767 -4.049,-1.785c-1.152,-1.018 -2.035,-2.313 -2.648,-3.885c-0.608,-1.558 -0.85,-3.139 -0.725,-4.743c0.125,-1.604 0.671,-2.972 1.638,-4.104c0.967,-1.133 2.247,-2.01 3.841,-2.632c1.157,-0.451 2.277,-0.672 3.358,-0.662c1.082,0.01 2.019,0.246 2.811,0.708c0.793,0.463 1.538,1.178 2.234,2.148l-1.706,1.269c-0.558,-0.721 -1.106,-1.245 -1.644,-1.572c-0.539,-0.327 -1.194,-0.501 -1.965,-0.523c-0.772,-0.021 -1.562,0.125 -2.37,0.44c-0.968,0.378 -1.747,0.852 -2.338,1.422c-0.591,0.571 -1.009,1.18 -1.255,1.83c-0.246,0.649 -0.376,1.308 -0.392,1.977c-0.022,1.149 0.194,2.302 0.645,3.459c0.557,1.426 1.268,2.524 2.134,3.293c0.866,0.769 1.86,1.203 2.98,1.303c1.121,0.099 2.209,-0.057 3.264,-0.469c0.917,-0.358 1.744,-0.883 2.479,-1.577c0.736,-0.694 1.251,-1.329 1.546,-1.905l-1.163,-2.981l-4.705,1.836Z"/>
                <path d="M196.726,54.83c-0.215,-0.792 -0.117,-1.543 0.293,-2.254c0.41,-0.71 1.007,-1.172 1.791,-1.385c0.792,-0.215 1.544,-0.12 2.257,0.285c0.713,0.406 1.178,1.004 1.393,1.796c0.215,0.791 0.118,1.544 -0.291,2.259c-0.409,0.714 -1.01,1.179 -1.801,1.394c-0.784,0.213 -1.533,0.115 -2.248,-0.294c-0.714,-0.409 -1.179,-1.01 -1.394,-1.801Z"/>
                <path d="M214.24,59.144l-2.871,-16.938l11.427,-1.937l0.339,1.999l-9.186,1.557l0.89,5.246l7.949,-1.348l0.339,1.999l-7.95,1.347l1.305,7.695l-2.242,0.38Z"/>
                <path d="M228.605,56.762l-1.423,-17.121l7.591,-0.631c1.526,-0.127 2.698,-0.07 3.518,0.172c0.82,0.241 1.499,0.732 2.039,1.471c0.54,0.739 0.849,1.579 0.927,2.521c0.101,1.215 -0.207,2.271 -0.924,3.17c-0.717,0.898 -1.888,1.529 -3.512,1.891c0.624,0.238 1.103,0.485 1.438,0.739c0.712,0.552 1.402,1.259 2.069,2.121l3.366,4.412l-2.85,0.237l-2.562,-3.374c-0.747,-0.972 -1.357,-1.713 -1.831,-2.223c-0.473,-0.509 -0.888,-0.859 -1.245,-1.048c-0.357,-0.19 -0.714,-0.313 -1.072,-0.37c-0.261,-0.033 -0.684,-0.025 -1.268,0.023l-2.627,0.219l0.632,7.602l-2.266,0.189Zm1.47,-9.753l4.87,-0.405c1.036,-0.086 1.837,-0.261 2.403,-0.523c0.566,-0.263 0.981,-0.643 1.246,-1.139c0.264,-0.496 0.373,-1.021 0.327,-1.573c-0.067,-0.81 -0.416,-1.451 -1.047,-1.924c-0.632,-0.473 -1.582,-0.656 -2.851,-0.551l-5.419,0.451l0.471,5.664Z"/>
                <path d="M245.226,47.056c0.016,-2.851 0.795,-5.079 2.335,-6.684c1.541,-1.604 3.522,-2.4 5.944,-2.386c1.586,0.009 3.013,0.397 4.282,1.162c1.269,0.765 2.234,1.827 2.894,3.186c0.66,1.36 0.985,2.899 0.976,4.617c-0.01,1.742 -0.371,3.299 -1.082,4.67c-0.711,1.371 -1.713,2.406 -3.006,3.106c-1.293,0.699 -2.686,1.045 -4.178,1.036c-1.617,-0.009 -3.06,-0.408 -4.329,-1.196c-1.269,-0.789 -2.228,-1.861 -2.876,-3.216c-0.649,-1.356 -0.969,-2.787 -0.96,-4.295Zm2.344,0.049c-0.012,2.07 0.535,3.704 1.641,4.902c1.107,1.198 2.5,1.801 4.179,1.811c1.711,0.01 3.123,-0.584 4.235,-1.78c1.113,-1.197 1.675,-2.901 1.688,-5.112c0.008,-1.398 -0.221,-2.62 -0.688,-3.666c-0.467,-1.046 -1.153,-1.858 -2.06,-2.438c-0.907,-0.579 -1.927,-0.872 -3.06,-0.879c-1.609,-0.009 -2.997,0.536 -4.163,1.635c-1.167,1.098 -1.758,2.941 -1.772,5.527Z"/>
                <path d="M264.496,55.732l1.677,-17.098l2.321,0.228l7.664,14.305l1.316,-13.424l2.17,0.213l-1.678,17.097l-2.32,-0.228l-7.663,-14.316l-1.318,13.436l-2.169,-0.213Z"/>
                <path d="M286.039,58.426l2.738,-14.903l-5.567,-1.023l0.366,-1.994l13.393,2.46l-0.366,1.994l-5.59,-1.027l-2.738,14.903l-2.236,-0.41Z"/>
                <path d="M296.186,60.405l4.473,-16.588l11.993,3.234l-0.527,1.958l-9.799,-2.642l-1.37,5.08l9.177,2.474l-0.525,1.946l-9.176,-2.474l-1.523,5.646l10.183,2.746l-0.527,1.957l-12.379,-3.337Z"/>
                <path d="M311.572,64.677l5.873,-16.145l2.192,0.798l3.868,15.76l4.612,-12.675l2.048,0.745l-5.874,16.144l-2.191,-0.797l-3.864,-15.772l-4.616,12.687l-2.048,-0.745Z"/>
                <path d="M327.821,70.761l7.34,-15.533l5.351,2.529c1.208,0.57 2.095,1.08 2.66,1.529c0.793,0.625 1.378,1.355 1.752,2.189c0.493,1.079 0.687,2.254 0.585,3.523c-0.103,1.269 -0.482,2.596 -1.136,3.98c-0.558,1.18 -1.189,2.16 -1.895,2.941c-0.707,0.781 -1.416,1.368 -2.129,1.761c-0.713,0.394 -1.406,0.64 -2.08,0.741c-0.674,0.101 -1.415,0.068 -2.223,-0.097c-0.807,-0.166 -1.68,-0.471 -2.62,-0.915l-5.605,-2.648Zm2.922,-0.862l3.316,1.567c1.024,0.484 1.873,0.769 2.546,0.853c0.673,0.085 1.264,0.036 1.774,-0.147c0.717,-0.257 1.407,-0.732 2.067,-1.427c0.661,-0.694 1.269,-1.628 1.823,-2.8c0.768,-1.625 1.091,-2.999 0.97,-4.124c-0.121,-1.124 -0.493,-2.015 -1.116,-2.672c-0.451,-0.472 -1.287,-0.997 -2.509,-1.575l-3.263,-1.542l-5.608,11.867Z"/>
                <path d="M349.262,81.573l9.221,-14.495l4.993,3.176c1.127,0.717 1.943,1.333 2.449,1.849c0.709,0.72 1.197,1.516 1.465,2.391c0.354,1.132 0.401,2.321 0.14,3.568c-0.26,1.246 -0.801,2.515 -1.623,3.807c-0.7,1.101 -1.449,1.995 -2.247,2.682c-0.798,0.687 -1.576,1.181 -2.332,1.482c-0.756,0.301 -1.475,0.46 -2.157,0.475c-0.681,0.016 -1.412,-0.108 -2.192,-0.373c-0.781,-0.265 -1.609,-0.677 -2.486,-1.234l-5.231,-3.328Zm3.007,-0.49l3.095,1.969c0.955,0.608 1.762,0.996 2.419,1.163c0.657,0.168 1.25,0.194 1.778,0.076c0.744,-0.165 1.487,-0.552 2.23,-1.158c0.742,-0.607 1.461,-1.457 2.157,-2.551c0.965,-1.516 1.457,-2.84 1.477,-3.971c0.02,-1.13 -0.238,-2.06 -0.774,-2.79c-0.388,-0.525 -1.152,-1.15 -2.293,-1.875l-3.045,-1.938l-7.044,11.075Z"/>
                <path d="M363.851,91.053l10.439,-13.644l9.866,7.548l-1.232,1.61l-8.06,-6.167l-3.197,4.179l7.548,5.775l-1.225,1.601l-7.548,-5.775l-3.553,4.644l8.376,6.409l-1.232,1.61l-10.182,-7.79Z"/>
                <path d="M380.087,104.151l6.566,-17.215l1.828,1.649l-5.046,12.257c-0.403,0.983 -0.807,1.881 -1.214,2.693c0.826,-0.518 1.683,-1.008 2.572,-1.468l11.806,-6.158l1.722,1.554l-16.502,8.25l-1.732,-1.562Z"/>
                <path d="M388.249,111.713l12.541,-11.741l8.489,9.068l-1.48,1.386l-6.935,-7.409l-3.841,3.596l6.495,6.938l-1.472,1.378l-6.495,-6.938l-4.269,3.996l7.208,7.7l-1.48,1.385l-8.761,-9.359Z"/>
                <path d="M398.979,123.404l13.403,-10.748l1.422,1.774l-11.821,9.479l5.292,6.601l-1.581,1.268l-6.715,-8.374Z"/>
                <path d="M413.921,128.654c2.361,-1.599 4.639,-2.216 6.834,-1.852c2.194,0.363 3.97,1.548 5.328,3.554c0.889,1.313 1.376,2.709 1.463,4.189c0.086,1.479 -0.245,2.875 -0.993,4.188c-0.748,1.313 -1.834,2.451 -3.257,3.415c-1.443,0.976 -2.931,1.559 -4.463,1.747c-1.533,0.189 -2.954,-0.052 -4.262,-0.724c-1.308,-0.671 -2.38,-1.624 -3.217,-2.86c-0.906,-1.339 -1.393,-2.755 -1.46,-4.247c-0.067,-1.493 0.276,-2.89 1.027,-4.191c0.751,-1.301 1.751,-2.374 3,-3.219Zm1.285,1.96c-1.715,1.161 -2.753,2.536 -3.116,4.126c-0.362,1.59 -0.073,3.08 0.869,4.471c0.959,1.417 2.246,2.246 3.862,2.487c1.616,0.241 3.34,-0.258 5.171,-1.498c1.158,-0.784 2.036,-1.664 2.635,-2.64c0.599,-0.976 0.881,-2.002 0.846,-3.077c-0.035,-1.076 -0.37,-2.083 -1.005,-3.021c-0.902,-1.332 -2.136,-2.169 -3.701,-2.51c-1.566,-0.341 -3.42,0.213 -5.561,1.662Z"/>
                <path d="M417.663,149.483l15.051,-8.284l3.125,5.677c0.55,1 0.922,1.789 1.116,2.369c0.284,0.807 0.39,1.555 0.32,2.245c-0.071,0.69 -0.334,1.359 -0.789,2.006c-0.455,0.647 -1.056,1.176 -1.802,1.587c-1.28,0.704 -2.587,0.893 -3.922,0.567c-1.334,-0.327 -2.587,-1.554 -3.759,-3.683l-2.125,-3.86l-6.118,3.368l-1.097,-1.992Zm8.991,-2.354l2.142,3.891c0.708,1.287 1.451,2.069 2.227,2.346c0.777,0.277 1.6,0.176 2.469,-0.302c0.63,-0.347 1.081,-0.803 1.355,-1.368c0.273,-0.565 0.338,-1.147 0.195,-1.746c-0.095,-0.384 -0.392,-1.028 -0.889,-1.932l-2.119,-3.85l-5.38,2.961Z"/>
                <path d="M425.313,163.496l15.747,-6.868l1.368,3.136l-9.523,8.591c-0.889,0.796 -1.556,1.386 -2.003,1.768c0.651,-0.071 1.614,-0.158 2.889,-0.263l12.601,-1.009l1.223,2.804l-15.747,6.868l-0.876,-2.008l13.18,-5.749l-15.176,1.173l-0.82,-1.88l11.419,-10.401l-13.405,5.847l-0.877,-2.009Z"/>
                <path d="M433.11,182.03l16.333,-5.328l3.852,11.809l-1.927,0.629l-3.148,-9.648l-5.002,1.632l2.948,9.035l-1.916,0.625l-2.948,-9.035l-5.559,1.814l3.271,10.027l-1.928,0.628l-3.976,-12.188Z"/>
                <path d="M437.946,197.237l16.728,-3.914l0.531,2.271l-11.078,11.859l13.134,-3.073l0.496,2.122l-16.728,3.914l-0.531,-2.271l11.09,-11.861l-13.145,3.075l-0.497,-2.122Z"/>
                <path d="M442.322,218.49l14.992,-2.2l-0.822,-5.6l2.006,-0.294l1.977,13.473l-2.006,0.294l-0.825,-5.623l-14.991,2.199l-0.331,-2.249Z"/>
                <path d="M452.498,233.892c0.819,-0.043 1.533,0.21 2.141,0.76c0.608,0.55 0.934,1.231 0.978,2.042c0.044,0.82 -0.208,1.535 -0.754,2.147c-0.546,0.612 -1.229,0.94 -2.048,0.984c-0.819,0.044 -1.535,-0.209 -2.147,-0.759c-0.612,-0.55 -0.94,-1.235 -0.984,-2.054c-0.044,-0.811 0.21,-1.523 0.76,-2.135c0.55,-0.613 1.235,-0.941 2.054,-0.985Z"/>
                <path d="M450.162,249.449l0.096,2.151c-0.863,0.065 -1.578,0.271 -2.144,0.618c-0.566,0.348 -1.035,0.904 -1.408,1.67c-0.373,0.766 -0.58,1.637 -0.622,2.613c-0.037,0.866 0.06,1.637 0.289,2.311c0.229,0.675 0.561,1.183 0.996,1.526c0.435,0.344 0.918,0.526 1.449,0.549c0.539,0.023 1.016,-0.113 1.431,-0.408c0.415,-0.295 0.775,-0.796 1.078,-1.503c0.2,-0.452 0.522,-1.459 0.968,-3.02c0.446,-1.56 0.85,-2.649 1.212,-3.267c0.465,-0.802 1.023,-1.39 1.677,-1.765c0.653,-0.374 1.374,-0.545 2.162,-0.511c0.866,0.036 1.666,0.317 2.398,0.841c0.732,0.524 1.273,1.266 1.623,2.227c0.35,0.961 0.501,2.019 0.452,3.175c-0.055,1.272 -0.307,2.385 -0.759,3.339c-0.451,0.955 -1.086,1.676 -1.904,2.165c-0.818,0.489 -1.732,0.732 -2.741,0.728l-0.071,-2.185c1.09,-0.07 1.927,-0.432 2.51,-1.083c0.583,-0.652 0.902,-1.637 0.959,-2.956c0.058,-1.374 -0.151,-2.386 -0.628,-3.035c-0.476,-0.65 -1.07,-0.99 -1.78,-1.021c-0.617,-0.026 -1.134,0.175 -1.551,0.603c-0.416,0.42 -0.873,1.544 -1.369,3.372c-0.496,1.829 -0.915,3.079 -1.257,3.753c-0.503,0.979 -1.118,1.692 -1.844,2.138c-0.727,0.446 -1.551,0.65 -2.472,0.61c-0.913,-0.039 -1.763,-0.337 -2.548,-0.894c-0.786,-0.557 -1.382,-1.336 -1.789,-2.334c-0.407,-0.999 -0.585,-2.111 -0.532,-3.336c0.066,-1.554 0.348,-2.846 0.845,-3.876c0.498,-1.031 1.214,-1.825 2.149,-2.384c0.934,-0.558 1.976,-0.828 3.125,-0.811Z"/>
                <path d="M452.209,266.414c2.826,0.378 4.937,1.433 6.333,3.165c1.396,1.732 1.933,3.798 1.611,6.199c-0.21,1.571 -0.775,2.938 -1.695,4.1c-0.921,1.161 -2.097,1.983 -3.529,2.466c-1.432,0.482 -3,0.609 -4.703,0.381c-1.727,-0.232 -3.225,-0.787 -4.495,-1.666c-1.269,-0.88 -2.169,-2.005 -2.699,-3.376c-0.529,-1.372 -0.695,-2.797 -0.497,-4.276c0.214,-1.603 0.793,-2.984 1.737,-4.142c0.943,-1.159 2.128,-1.974 3.555,-2.445c1.426,-0.471 2.887,-0.606 4.382,-0.406Zm-0.346,2.318c-2.052,-0.275 -3.743,0.061 -5.071,1.006c-1.329,0.945 -2.105,2.251 -2.328,3.915c-0.227,1.696 0.183,3.172 1.229,4.427c1.045,1.255 2.664,2.03 4.855,2.323c1.386,0.186 2.628,0.114 3.724,-0.217c1.097,-0.33 1.99,-0.908 2.68,-1.733c0.69,-0.826 1.11,-1.801 1.26,-2.924c0.214,-1.595 -0.15,-3.041 -1.092,-4.337c-0.942,-1.297 -2.694,-2.117 -5.257,-2.46Z"/>
                <path d="M441.091,284.571l16.757,3.786l-2.554,11.305l-1.978,-0.447l2.053,-9.087l-5.189,-1.173l-1.777,7.865l-1.977,-0.447l1.776,-7.864l-7.612,-1.721l0.501,-2.217Z"/>
                <path d="M436.491,302.901l14.489,4.433l1.657,-5.412l1.938,0.593l-3.984,13.021l-1.939,-0.593l1.663,-5.435l-14.489,-4.433l0.665,-2.174Z"/>
                <path d="M432.333,315.542l17.611,2.398l-0.896,2.153l-11.401,-1.918c-1.197,-0.194 -2.372,-0.421 -3.525,-0.681c1.527,1.11 2.405,1.755 2.634,1.935l9.453,7.479l-1.054,2.531l-8.973,-1.071c-2.228,-0.26 -4.271,-0.628 -6.129,-1.106c0.919,0.62 1.959,1.362 3.122,2.227l9.155,6.733l-0.879,2.11l-14.048,-10.955l0.843,-2.023l13.476,1.691c1.127,0.139 1.816,0.231 2.068,0.277c-0.66,-0.469 -1.215,-0.883 -1.667,-1.24l-10.683,-8.398l0.893,-2.142Z"/>
                <path d="M425.74,331.074l12.042,13.917l-1.155,2.16l-18.465,-1.897l1.22,-2.284l5.534,0.685l3.386,-6.335l-3.699,-4.117l1.137,-2.129Zm3.886,7.697l-2.746,5.137l5.041,0.661c1.533,0.199 2.772,0.401 3.717,0.605c-0.869,-0.712 -1.688,-1.5 -2.458,-2.363l-3.554,-4.04Z"/>
                <path d="M417.201,346.827l14.356,9.438l-4.185,6.365c-0.841,1.279 -1.609,2.167 -2.305,2.663c-0.695,0.497 -1.501,0.729 -2.415,0.699c-0.915,-0.031 -1.767,-0.306 -2.557,-0.826c-1.018,-0.669 -1.66,-1.563 -1.925,-2.682c-0.265,-1.118 -0.04,-2.429 0.677,-3.931c-0.572,0.344 -1.062,0.57 -1.468,0.676c-0.874,0.22 -1.856,0.328 -2.946,0.322l-5.549,-0.072l1.571,-2.389l4.235,0.063c1.227,0.012 2.187,-0.012 2.88,-0.07c0.693,-0.059 1.224,-0.17 1.593,-0.334c0.37,-0.164 0.687,-0.369 0.952,-0.615c0.187,-0.186 0.442,-0.523 0.764,-1.013l1.448,-2.203l-6.375,-4.191l1.249,-1.9Zm6.771,7.172l-2.684,4.084c-0.571,0.868 -0.928,1.606 -1.07,2.214c-0.142,0.607 -0.1,1.168 0.128,1.682c0.228,0.515 0.573,0.924 1.037,1.229c0.679,0.446 1.399,0.567 2.16,0.361c0.761,-0.205 1.492,-0.84 2.191,-1.904l2.987,-4.544l-4.749,-3.122Z"/>
                <path d="M407.55,361.25l13.514,10.607l-7.669,9.771l-1.595,-1.251l6.266,-7.984l-4.139,-3.248l-5.868,7.476l-1.585,-1.244l5.867,-7.476l-4.6,-3.611l-6.511,8.297l-1.595,-1.252l7.915,-10.085Z"/>
                <path d="M393.094,378.732l12.182,12.114l-8.759,8.808l-1.437,-1.43l7.156,-7.196l-3.731,-3.71l-6.702,6.739l-1.429,-1.421l6.701,-6.739l-4.146,-4.124l-7.437,7.479l-1.437,-1.43l9.039,-9.09Z"/>
                <path d="M381.769,389.955l11.121,13.095l-1.778,1.51l-15.609,-4.441l8.731,10.282l-1.661,1.411l-11.121,-13.095l1.778,-1.51l15.616,4.449l-8.738,-10.29l1.661,-1.411Z"/>
                <path d="M365.734,411.202l1.156,1.651l-5.953,4.185l-3.658,-5.221c0.404,-1.37 0.963,-2.58 1.678,-3.629c0.715,-1.05 1.584,-1.933 2.608,-2.65c1.382,-0.969 2.845,-1.553 4.389,-1.752c1.543,-0.199 2.996,0.059 4.356,0.775c1.36,0.716 2.525,1.765 3.493,3.147c0.96,1.37 1.569,2.848 1.828,4.436c0.259,1.589 0.052,3.047 -0.619,4.376c-0.671,1.329 -1.708,2.485 -3.109,3.466c-1.017,0.713 -2.052,1.193 -3.105,1.439c-1.053,0.247 -2.02,0.24 -2.899,-0.022c-0.88,-0.261 -1.773,-0.78 -2.679,-1.557l1.356,-1.637c0.714,0.568 1.37,0.948 1.971,1.138c0.6,0.189 1.278,0.203 2.033,0.042c0.755,-0.162 1.487,-0.491 2.197,-0.989c0.851,-0.596 1.496,-1.241 1.935,-1.935c0.439,-0.694 0.701,-1.386 0.786,-2.075c0.085,-0.689 0.056,-1.36 -0.087,-2.014c-0.252,-1.121 -0.734,-2.19 -1.447,-3.207c-0.878,-1.254 -1.829,-2.152 -2.853,-2.694c-1.024,-0.542 -2.092,-0.729 -3.204,-0.56c-1.112,0.169 -2.132,0.578 -3.06,1.228c-0.806,0.565 -1.484,1.272 -2.035,2.12c-0.55,0.848 -0.9,1.587 -1.051,2.217l1.836,2.62l4.137,-2.898Z"/>
                <path d="M352.625,411.729l8.899,14.695l-1.944,1.178l-8.899,-14.696l1.944,-1.177Z"/>
                <path d="M347.309,415.008l7.975,15.217l-2.066,1.082l-14.253,-7.758l6.261,11.947l-1.931,1.012l-7.974,-15.217l2.065,-1.083l14.259,7.769l-6.266,-11.957l1.93,-1.012Z"/>
                <path d="M331.78,422.932l6.588,15.867l-11.473,4.763l-0.777,-1.873l9.373,-3.891l-2.018,-4.859l-8.777,3.644l-0.773,-1.862l8.777,-3.644l-2.242,-5.401l-9.741,4.044l-0.777,-1.872l11.84,-4.916Z"/>
                <path d="M316.963,428.94l5.236,16.363l-11.83,3.786l-0.618,-1.931l9.665,-3.093l-1.604,-5.012l-9.051,2.897l-0.615,-1.919l9.052,-2.897l-1.782,-5.57l-10.045,3.215l-0.618,-1.931l12.21,-3.908Z"/>
                <path d="M301.671,433.737l3.744,16.767l-7.434,1.66c-1.495,0.334 -2.664,0.437 -3.509,0.309c-0.845,-0.127 -1.585,-0.52 -2.221,-1.179c-0.635,-0.659 -1.056,-1.449 -1.262,-2.372c-0.265,-1.189 -0.104,-2.278 0.484,-3.266c0.588,-0.987 1.661,-1.772 3.221,-2.352c-0.65,-0.151 -1.158,-0.33 -1.525,-0.536c-0.781,-0.45 -1.56,-1.056 -2.339,-1.819l-3.935,-3.912l2.79,-0.623l2.998,2.992c0.873,0.862 1.578,1.513 2.117,1.953c0.538,0.44 0.997,0.73 1.377,0.87c0.379,0.139 0.75,0.212 1.111,0.22c0.264,-0.003 0.682,-0.068 1.254,-0.196l2.573,-0.575l-1.662,-7.445l2.218,-0.496Zm-0.127,9.863l-4.769,1.065c-1.014,0.226 -1.784,0.508 -2.309,0.845c-0.525,0.338 -0.885,0.77 -1.079,1.298c-0.195,0.528 -0.231,1.062 -0.11,1.603c0.177,0.793 0.61,1.381 1.3,1.763c0.689,0.383 1.656,0.435 2.899,0.158l5.306,-1.185l-1.238,-5.547Z"/>
                <path d="M284.365,437.331l2.751,16.958l-2.244,0.364l-2.752,-16.958l2.245,-0.364Z"/>
                <path d="M278.193,438.384l1.697,17.095l-2.32,0.231l-10.312,-12.531l1.333,13.422l-2.17,0.216l-1.697,-17.096l2.321,-0.231l10.313,12.543l-1.334,-13.434l2.169,-0.215Z"/>
                <path d="M252.924,446.738l0.013,2.016l-7.277,0.059l-0.042,-6.375c1.112,-0.898 2.26,-1.575 3.444,-2.032c1.185,-0.457 2.402,-0.69 3.652,-0.698c1.687,-0.011 3.223,0.341 4.607,1.054c1.383,0.714 2.431,1.752 3.143,3.114c0.712,1.363 1.074,2.888 1.085,4.575c0.011,1.672 -0.329,3.235 -1.019,4.689c-0.689,1.454 -1.688,2.536 -2.996,3.248c-1.308,0.712 -2.817,1.073 -4.528,1.084c-1.242,0.009 -2.367,-0.185 -3.373,-0.581c-1.007,-0.396 -1.797,-0.951 -2.372,-1.666c-0.575,-0.715 -1.015,-1.65 -1.32,-2.804l2.048,-0.576c0.263,0.873 0.588,1.559 0.974,2.056c0.386,0.498 0.935,0.894 1.648,1.191c0.713,0.296 1.503,0.441 2.37,0.435c1.039,-0.007 1.937,-0.171 2.693,-0.492c0.755,-0.321 1.364,-0.741 1.825,-1.26c0.462,-0.519 0.819,-1.087 1.073,-1.706c0.431,-1.066 0.642,-2.219 0.634,-3.462c-0.01,-1.531 -0.282,-2.81 -0.816,-3.838c-0.534,-1.028 -1.307,-1.788 -2.318,-2.282c-1.011,-0.493 -2.083,-0.736 -3.216,-0.729c-0.984,0.007 -1.944,0.202 -2.879,0.587c-0.935,0.385 -1.643,0.794 -2.125,1.227l0.021,3.199l5.051,-0.033Z"/>
                <path d="M235.358,447.732c-0.081,0.816 -0.439,1.484 -1.075,2.002c-0.636,0.518 -1.358,0.738 -2.167,0.658c-0.816,-0.081 -1.485,-0.437 -2.008,-1.069c-0.523,-0.633 -0.744,-1.357 -0.663,-2.173c0.08,-0.817 0.439,-1.486 1.075,-2.008c0.636,-0.522 1.362,-0.743 2.179,-0.663c0.808,0.08 1.474,0.438 1.996,1.074c0.522,0.636 0.743,1.363 0.663,2.179Z"/>
                <path d="M223.785,438.072l-9.716,15.629l-2.406,-0.462l-3.669,-18.196l2.544,0.488l0.988,5.487l7.055,1.353l2.833,-4.754l2.371,0.455Zm-6.198,5.994l-5.72,-1.097l0.865,5.01c0.265,1.523 0.44,2.766 0.526,3.729c0.422,-1.041 0.931,-2.057 1.527,-3.048l2.802,-4.594Z"/>
                <path d="M190.724,448.024l-2.187,-0.621l2.711,-9.548c0.471,-1.661 1.033,-2.927 1.687,-3.797c0.653,-0.871 1.557,-1.473 2.711,-1.807c1.155,-0.334 2.529,-0.275 4.122,0.177c1.548,0.44 2.739,1.066 3.572,1.879c0.833,0.813 1.317,1.785 1.451,2.915c0.133,1.13 -0.048,2.571 -0.545,4.322l-2.711,9.549l-2.187,-0.621l2.708,-9.537c0.407,-1.436 0.574,-2.531 0.5,-3.287c-0.074,-0.756 -0.383,-1.411 -0.929,-1.963c-0.545,-0.553 -1.288,-0.963 -2.227,-1.23c-1.609,-0.456 -2.858,-0.417 -3.749,0.118c-0.891,0.535 -1.631,1.839 -2.22,3.914l-2.707,9.537Z"/>
                <path d="M179.655,433.055l-0.73,1.878l-6.788,-2.625l2.31,-5.942c1.364,-0.426 2.68,-0.632 3.95,-0.621c1.27,0.011 2.487,0.244 3.652,0.696c1.573,0.612 2.871,1.504 3.895,2.677c1.023,1.173 1.615,2.524 1.775,4.053c0.16,1.529 -0.066,3.08 -0.677,4.653c-0.605,1.558 -1.497,2.886 -2.673,3.984c-1.177,1.097 -2.504,1.736 -3.983,1.916c-1.478,0.18 -3.014,-0.04 -4.609,-0.66c-1.158,-0.45 -2.131,-1.044 -2.921,-1.783c-0.79,-0.739 -1.321,-1.546 -1.592,-2.423c-0.271,-0.876 -0.336,-1.907 -0.194,-3.092l2.115,0.218c-0.076,0.909 -0.027,1.666 0.149,2.271c0.176,0.604 0.54,1.176 1.094,1.714c0.554,0.537 1.235,0.963 2.043,1.278c0.969,0.376 1.863,0.554 2.684,0.534c0.821,-0.021 1.542,-0.187 2.162,-0.499c0.62,-0.313 1.162,-0.71 1.626,-1.191c0.792,-0.832 1.414,-1.827 1.864,-2.985c0.554,-1.427 0.773,-2.717 0.655,-3.869c-0.118,-1.152 -0.556,-2.144 -1.315,-2.975c-0.758,-0.831 -1.665,-1.452 -2.721,-1.862c-0.917,-0.357 -1.882,-0.529 -2.893,-0.515c-1.011,0.014 -1.82,0.133 -2.427,0.358l-1.159,2.982l4.708,1.83Z"/>
                <path d="M172.328,422.813l-7.812,15.301l-3.048,-1.556l1.91,-12.683c0.181,-1.179 0.323,-2.059 0.426,-2.638c-0.458,0.468 -1.147,1.147 -2.067,2.037l-9.099,8.775l-2.724,-1.39l7.813,-15.301l1.951,0.996l-6.538,12.807l10.985,-10.536l1.826,0.932l-2.225,15.285l6.65,-13.025l1.952,0.996Z"/>
                <path d="M154.531,413.43l-9.234,14.488l-10.475,-6.677l1.09,-1.71l8.558,5.455l2.828,-4.437l-8.014,-5.108l1.083,-1.7l8.014,5.108l3.143,-4.931l-8.893,-5.669l1.089,-1.709l10.811,6.89Z"/>
                <path d="M141.142,404.757l-10.424,13.656l-1.853,-1.415l1.011,-16.197l-8.184,10.722l-1.733,-1.323l10.424,-13.656l1.854,1.415l-1.019,16.206l8.192,-10.731l1.732,1.323Z"/>
                <path d="M124.245,391.137l-10.163,11.239l4.199,3.796l-1.36,1.504l-10.1,-9.133l1.359,-1.504l4.216,3.812l10.163,-11.239l1.686,1.525Z"/>
                <path d="M116.642,384.111l-12.511,11.773l-8.513,-9.046l1.476,-1.389l6.956,7.39l3.831,-3.606l-6.513,-6.921l1.468,-1.381l6.513,6.921l4.259,-4.008l-7.229,-7.68l1.477,-1.39l8.786,9.337Z"/>
                <path d="M105.769,372.402l-13.477,10.655l-3.67,-4.643c-0.828,-1.048 -1.396,-1.898 -1.704,-2.552c-0.432,-0.912 -0.632,-1.825 -0.597,-2.739c0.039,-1.186 0.386,-2.324 1.042,-3.416c0.656,-1.091 1.584,-2.111 2.786,-3.061c1.023,-0.809 2.025,-1.407 3.004,-1.793c0.98,-0.386 1.876,-0.597 2.69,-0.632c0.813,-0.036 1.544,0.051 2.193,0.261c0.648,0.209 1.297,0.567 1.947,1.074c0.65,0.507 1.297,1.168 1.941,1.983l3.845,4.863Zm-3,-0.526l-2.275,-2.877c-0.703,-0.889 -1.336,-1.52 -1.902,-1.895c-0.565,-0.375 -1.116,-0.594 -1.654,-0.657c-0.757,-0.088 -1.586,0.032 -2.487,0.36c-0.9,0.329 -1.859,0.895 -2.876,1.699c-1.41,1.115 -2.31,2.202 -2.701,3.264c-0.391,1.061 -0.454,2.024 -0.188,2.889c0.194,0.624 0.71,1.465 1.548,2.526l2.239,2.831l10.296,-8.14Z"/>
                <path d="M91.344,353.202l-14.717,8.863l-3.929,-6.525c-0.79,-1.312 -1.259,-2.389 -1.405,-3.231c-0.147,-0.842 -0.009,-1.668 0.415,-2.479c0.423,-0.812 1.04,-1.461 1.849,-1.949c1.044,-0.629 2.128,-0.821 3.251,-0.576c1.123,0.245 2.207,1.015 3.252,2.31c-0.063,-0.665 -0.055,-1.203 0.025,-1.616c0.18,-0.883 0.508,-1.815 0.984,-2.795l2.464,-4.972l1.475,2.449l-1.889,3.792c-0.54,1.101 -0.934,1.976 -1.181,2.626c-0.247,0.651 -0.376,1.178 -0.388,1.582c-0.012,0.404 0.035,0.778 0.143,1.124c0.086,0.249 0.281,0.624 0.583,1.126l1.36,2.259l6.535,-3.936l1.173,1.948Zm-9.394,3.004l-2.521,-4.186c-0.537,-0.891 -1.048,-1.531 -1.534,-1.922c-0.486,-0.391 -1.011,-0.595 -1.573,-0.613c-0.562,-0.017 -1.08,0.118 -1.556,0.404c-0.696,0.419 -1.116,1.016 -1.26,1.792c-0.144,0.775 0.113,1.708 0.77,2.799l2.805,4.658l4.869,-2.932Z"/>
                <path d="M82.506,338.25l-15.412,7.59l-5.488,-11.143l1.819,-0.896l4.484,9.104l4.72,-2.325l-4.199,-8.526l1.808,-0.89l4.199,8.526l5.246,-2.584l-4.66,-9.462l1.819,-0.895l5.664,11.501Z"/>
                <path d="M76.266,325.614l-18.402,0.162l-0.898,-2.278l13.404,-12.842l0.95,2.41l-4.106,3.772l2.635,6.683l5.532,-0.153l0.885,2.246Zm-8.381,-2.024l-2.136,-5.419l-3.768,3.413c-1.144,1.039 -2.1,1.852 -2.868,2.44c1.105,-0.2 2.236,-0.314 3.392,-0.342l5.38,-0.092Z"/>
                <path d="M69.901,308.991l-16.432,5.014l-0.663,-2.174l14.492,-4.423l-2.469,-8.092l1.939,-0.592l3.133,10.267Z"/>
                <path d="M66.041,295.71l-16.663,4.181l-0.553,-2.205l16.663,-4.181l0.553,2.205Z"/>
                <path d="M63.681,285.349l-14.879,2.863l1.069,5.558l-1.991,0.383l-2.573,-13.372l1.991,-0.383l1.074,5.581l14.879,-2.863l0.43,2.233Z"/>
                <path d="M61.321,270.403l-7.233,0.803l-9.111,7.674l-0.305,-2.749l4.774,-3.938c0.894,-0.728 1.792,-1.413 2.696,-2.056c-0.95,-0.413 -2.025,-0.923 -3.224,-1.528l-5.387,-2.751l-0.293,-2.632l10.599,5.721l7.233,-0.804l0.251,2.26Z"/>
                <path d="M51.964,260.494c-0.819,0.04 -1.531,-0.217 -2.137,-0.771c-0.605,-0.553 -0.928,-1.236 -0.967,-2.047c-0.039,-0.82 0.216,-1.534 0.765,-2.143c0.55,-0.609 1.234,-0.934 2.053,-0.973c0.82,-0.04 1.534,0.217 2.144,0.771c0.609,0.553 0.933,1.239 0.973,2.059c0.039,0.811 -0.218,1.522 -0.772,2.131c-0.553,0.609 -1.239,0.934 -2.059,0.973Z"/>
            </g>
            <g id="Outer-Circle-3" className="outer-circle-3" transform-origin="250 250">
                <path d="M20.08,243.784l-15.145,-0.477l-0.178,5.657l-2.027,-0.064l0.429,-13.61l2.027,0.064l-0.179,5.68l15.144,0.478l-0.071,2.272Z"/>
                <path d="M12.036,233.777c-2.837,-0.289 -4.98,-1.277 -6.43,-2.964c-1.449,-1.687 -2.052,-3.735 -1.806,-6.144c0.161,-1.578 0.682,-2.962 1.565,-4.152c0.883,-1.19 2.033,-2.049 3.449,-2.576c1.416,-0.527 2.979,-0.703 4.689,-0.529c1.734,0.176 3.249,0.684 4.545,1.523c1.297,0.839 2.232,1.935 2.804,3.289c0.573,1.354 0.784,2.774 0.632,4.258c-0.163,1.609 -0.699,3.007 -1.605,4.195c-0.906,1.188 -2.065,2.04 -3.476,2.555c-1.411,0.516 -2.867,0.698 -4.367,0.545Zm0.273,-2.328c2.059,0.21 3.738,-0.179 5.036,-1.166c1.299,-0.986 2.033,-2.315 2.203,-3.986c0.173,-1.702 -0.282,-3.164 -1.367,-4.386c-1.085,-1.222 -2.727,-1.945 -4.927,-2.169c-1.391,-0.142 -2.629,-0.03 -3.715,0.334c-1.085,0.365 -1.96,0.971 -2.623,1.818c-0.664,0.847 -1.053,1.834 -1.168,2.961c-0.163,1.601 0.246,3.035 1.229,4.301c0.982,1.267 2.759,2.031 5.332,2.293Z"/>
                <path d="M22.471,215.454l-16.9,-3.087l0.408,-2.236l8.381,1.531l-6.848,-9.924l0.554,-3.031l5.553,8.34l11.404,-5.564l-0.539,2.951l-9.653,4.455l2.192,3.259l5.856,1.07l-0.408,2.236Z"/>
                <path d="M26.644,194.927l-7.057,-1.778l-11.22,4.001l0.676,-2.681l5.85,-2.019c1.092,-0.369 2.173,-0.697 3.244,-0.983c-0.745,-0.72 -1.573,-1.573 -2.485,-2.56l-4.084,-4.461l0.647,-2.568l7.927,9.067l7.057,1.778l-0.555,2.204Z"/>
                <path d="M21.31,182.107c-2.703,-0.906 -4.577,-2.342 -5.62,-4.306c-1.044,-1.964 -1.18,-4.095 -0.411,-6.391c0.504,-1.504 1.317,-2.739 2.44,-3.706c1.123,-0.967 2.434,-1.551 3.931,-1.754c1.498,-0.203 3.061,-0.031 4.691,0.515c1.652,0.553 3.018,1.382 4.098,2.485c1.081,1.104 1.751,2.379 2.012,3.826c0.261,1.447 0.155,2.877 -0.319,4.292c-0.514,1.534 -1.344,2.78 -2.489,3.739c-1.145,0.96 -2.463,1.536 -3.953,1.729c-1.49,0.193 -2.95,0.05 -4.38,-0.429Zm0.778,-2.212c1.963,0.658 3.687,0.648 5.17,-0.029c1.483,-0.677 2.492,-1.812 3.025,-3.405c0.544,-1.622 0.421,-3.148 -0.369,-4.579c-0.789,-1.43 -2.232,-2.496 -4.329,-3.199c-1.326,-0.444 -2.558,-0.608 -3.697,-0.491c-1.14,0.117 -2.126,0.516 -2.959,1.196c-0.834,0.681 -1.431,1.558 -1.791,2.632c-0.511,1.526 -0.427,3.015 0.253,4.466c0.68,1.451 2.245,2.588 4.697,3.409Z"/>
                <path d="M30.206,157.494c-0.752,-0.328 -1.276,-0.875 -1.573,-1.639c-0.297,-0.765 -0.283,-1.52 0.042,-2.264c0.329,-0.752 0.874,-1.279 1.637,-1.581c0.763,-0.302 1.52,-0.289 2.272,0.039c0.751,0.328 1.277,0.876 1.578,1.642c0.3,0.766 0.287,1.525 -0.042,2.277c-0.325,0.745 -0.87,1.267 -1.637,1.568c-0.766,0.3 -1.525,0.286 -2.277,-0.042Z"/>
                <path d="M39.201,144.693l0.851,-1.978c0.805,0.318 1.538,0.444 2.199,0.378c0.661,-0.066 1.326,-0.362 1.995,-0.889c0.669,-0.527 1.235,-1.221 1.698,-2.081c0.411,-0.764 0.66,-1.499 0.747,-2.206c0.088,-0.707 0.011,-1.31 -0.231,-1.808c-0.242,-0.498 -0.597,-0.873 -1.065,-1.125c-0.475,-0.255 -0.963,-0.341 -1.466,-0.256c-0.502,0.085 -1.044,0.379 -1.625,0.882c-0.377,0.321 -1.106,1.086 -2.187,2.297c-1.081,1.21 -1.92,2.014 -2.515,2.413c-0.767,0.518 -1.527,0.804 -2.278,0.857c-0.751,0.053 -1.474,-0.108 -2.169,-0.482c-0.764,-0.411 -1.361,-1.011 -1.792,-1.802c-0.431,-0.79 -0.594,-1.694 -0.491,-2.712c0.104,-1.018 0.43,-2.036 0.978,-3.054c0.603,-1.121 1.316,-2.013 2.138,-2.675c0.821,-0.663 1.707,-1.036 2.657,-1.119c0.95,-0.084 1.878,0.096 2.784,0.539l-0.888,1.997c-1.012,-0.411 -1.922,-0.45 -2.731,-0.118c-0.809,0.333 -1.526,1.08 -2.151,2.243c-0.651,1.211 -0.904,2.213 -0.758,3.005c0.146,0.793 0.531,1.357 1.158,1.694c0.543,0.293 1.096,0.337 1.658,0.134c0.558,-0.197 1.459,-1.01 2.702,-2.439c1.243,-1.429 2.165,-2.373 2.766,-2.83c0.88,-0.662 1.744,-1.036 2.592,-1.121c0.849,-0.084 1.679,0.092 2.491,0.528c0.805,0.433 1.439,1.072 1.904,1.916c0.464,0.844 0.661,1.804 0.593,2.88c-0.069,1.077 -0.394,2.155 -0.975,3.235c-0.737,1.369 -1.553,2.409 -2.45,3.12c-0.897,0.711 -1.888,1.114 -2.972,1.209c-1.085,0.095 -2.141,-0.116 -3.167,-0.632Z"/>
                <path d="M51.124,134.182l-10.999,-14.755l1.308,-2.07l18.279,3.232l-1.383,2.19l-5.469,-1.085l-3.837,6.072l3.391,4.375l-1.29,2.041Zm-3.316,-7.959l3.111,-4.924l-4.98,-1.026c-1.514,-0.31 -2.735,-0.6 -3.663,-0.872c0.815,0.773 1.575,1.618 2.28,2.535l3.252,4.287Z"/>
                <path d="M60.748,119.154l-13.827,-10.195l1.384,-1.877l16.211,0.742l-10.856,-8.005l1.293,-1.754l13.828,10.195l-1.384,1.877l-16.221,-0.749l10.866,8.012l-1.294,1.754Z"/>
                <path d="M75.544,100.067l-12.739,-11.527l7.777,-8.594l1.503,1.361l-6.251,6.908l3.945,3.569l5.409,-5.978l1.504,1.361l-5.41,5.978l5.787,5.236l-1.525,1.686Z"/>
                <path d="M85.354,89.289l-11.894,-12.396l5.496,-5.274c1.105,-1.06 2.052,-1.755 2.841,-2.084c0.788,-0.329 1.625,-0.376 2.51,-0.143c0.884,0.234 1.654,0.692 2.309,1.374c0.843,0.88 1.27,1.894 1.279,3.044c0.01,1.149 -0.502,2.376 -1.534,3.681c0.634,-0.208 1.161,-0.319 1.581,-0.332c0.901,-0.02 1.882,0.094 2.944,0.343l5.393,1.305l-2.063,1.979l-4.115,-1.005c-1.193,-0.284 -2.134,-0.475 -2.823,-0.572c-0.688,-0.097 -1.231,-0.107 -1.628,-0.029c-0.396,0.077 -0.751,0.206 -1.065,0.388c-0.223,0.139 -0.547,0.411 -0.97,0.817l-1.902,1.825l5.282,5.505l-1.641,1.574Zm-5.004,-8.499l3.526,-3.384c0.75,-0.719 1.262,-1.359 1.536,-1.92c0.274,-0.56 0.357,-1.117 0.25,-1.669c-0.108,-0.552 -0.354,-1.028 -0.738,-1.428c-0.562,-0.586 -1.237,-0.864 -2.025,-0.833c-0.788,0.03 -1.642,0.486 -2.561,1.368l-3.923,3.765l3.935,4.101Z"/>
                <path d="M96.47,78.614l-5.955,-17.413l1.879,-1.571l16.413,8.673l-1.987,1.661l-4.875,-2.708l-5.512,4.607l1.889,5.203l-1.852,1.548Zm-0.721,-8.592l4.469,-3.735l-4.427,-2.501c-1.346,-0.758 -2.419,-1.409 -3.22,-1.952c0.539,0.986 1.004,2.023 1.394,3.111l1.784,5.077Z"/>
                <path d="M110.277,67.219l-10.042,-13.94l1.892,-1.363l15.206,5.67l-7.884,-10.944l1.768,-1.274l10.042,13.939l-1.892,1.363l-15.213,-5.679l7.892,10.953l-1.769,1.275Z"/>
                <path d="M131.768,45.652l2.24,-0.695c0.566,1.842 0.577,3.504 0.031,4.986c-0.546,1.483 -1.582,2.69 -3.109,3.622c-1.58,0.965 -3.062,1.429 -4.445,1.39c-1.383,-0.039 -2.705,-0.51 -3.967,-1.415c-1.262,-0.905 -2.321,-2.057 -3.176,-3.457c-0.932,-1.527 -1.454,-3.037 -1.565,-4.53c-0.111,-1.493 0.192,-2.863 0.908,-4.111c0.717,-1.248 1.722,-2.267 3.015,-3.057c1.467,-0.895 2.929,-1.275 4.385,-1.139c1.456,0.136 2.794,0.76 4.014,1.873l-1.635,1.617c-0.992,-0.859 -1.96,-1.335 -2.904,-1.426c-0.944,-0.092 -1.906,0.161 -2.886,0.76c-1.127,0.688 -1.903,1.533 -2.33,2.536c-0.427,1.002 -0.516,2.052 -0.267,3.149c0.249,1.097 0.669,2.13 1.259,3.096c0.762,1.247 1.608,2.225 2.54,2.933c0.931,0.708 1.921,1.058 2.968,1.05c1.048,-0.008 2.019,-0.285 2.912,-0.831c1.087,-0.664 1.815,-1.539 2.186,-2.626c0.371,-1.087 0.313,-2.328 -0.174,-3.725Z"/>
                <path d="M139.674,48.173l-8.184,-15.105l1.999,-1.083l8.184,15.105l-1.999,1.083Z"/>
                <path d="M142.123,40.508l1.852,-1.097c0.464,0.731 0.982,1.264 1.555,1.6c0.573,0.336 1.285,0.488 2.136,0.456c0.852,-0.031 1.718,-0.258 2.598,-0.681c0.782,-0.375 1.416,-0.823 1.903,-1.343c0.487,-0.519 0.78,-1.052 0.878,-1.597c0.098,-0.546 0.032,-1.058 -0.198,-1.537c-0.234,-0.486 -0.578,-0.842 -1.034,-1.07c-0.456,-0.227 -1.067,-0.309 -1.833,-0.244c-0.494,0.037 -1.534,0.227 -3.12,0.568c-1.587,0.341 -2.738,0.498 -3.454,0.469c-0.926,-0.033 -1.707,-0.249 -2.346,-0.649c-0.638,-0.4 -1.128,-0.955 -1.469,-1.667c-0.376,-0.781 -0.504,-1.619 -0.387,-2.511c0.117,-0.893 0.518,-1.72 1.201,-2.481c0.683,-0.761 1.546,-1.392 2.588,-1.893c1.148,-0.551 2.249,-0.852 3.304,-0.903c1.054,-0.051 1.99,0.169 2.806,0.661c0.817,0.492 1.461,1.184 1.933,2.075l-1.894,1.092c-0.576,-0.929 -1.288,-1.497 -2.138,-1.704c-0.849,-0.208 -1.869,-0.026 -3.059,0.545c-1.24,0.595 -2.034,1.256 -2.383,1.983c-0.349,0.726 -0.369,1.41 -0.062,2.051c0.267,0.556 0.688,0.918 1.262,1.084c0.567,0.17 1.773,0.043 3.62,-0.379c1.847,-0.423 3.147,-0.642 3.902,-0.658c1.101,-0.018 2.02,0.189 2.755,0.621c0.736,0.431 1.303,1.062 1.702,1.893c0.396,0.824 0.532,1.714 0.41,2.669c-0.121,0.956 -0.527,1.848 -1.217,2.677c-0.689,0.83 -1.587,1.51 -2.693,2.041c-1.401,0.672 -2.674,1.032 -3.817,1.078c-1.143,0.046 -2.181,-0.211 -3.114,-0.773c-0.933,-0.562 -1.662,-1.354 -2.187,-2.376Z"/>
                <path d="M168.903,28.238l2.326,-0.296c0.238,1.912 -0.041,3.551 -0.835,4.916c-0.795,1.365 -2.025,2.374 -3.691,3.027c-1.724,0.676 -3.263,0.875 -4.619,0.597c-1.355,-0.279 -2.575,-0.973 -3.661,-2.083c-1.086,-1.11 -1.928,-2.428 -2.527,-3.956c-0.653,-1.665 -0.905,-3.243 -0.755,-4.732c0.15,-1.49 0.686,-2.787 1.609,-3.891c0.922,-1.105 2.088,-1.934 3.499,-2.487c1.601,-0.628 3.106,-0.748 4.516,-0.361c1.411,0.387 2.62,1.234 3.628,2.542l-1.891,1.308c-0.827,-1.018 -1.698,-1.654 -2.612,-1.908c-0.913,-0.255 -1.905,-0.172 -2.974,0.247c-1.229,0.482 -2.141,1.18 -2.735,2.093c-0.595,0.912 -0.865,1.931 -0.81,3.055c0.055,1.124 0.289,2.213 0.703,3.268c0.533,1.36 1.197,2.469 1.991,3.329c0.795,0.859 1.708,1.375 2.742,1.549c1.033,0.174 2.037,0.07 3.011,-0.313c1.186,-0.465 2.055,-1.2 2.609,-2.206c0.554,-1.006 0.712,-2.239 0.476,-3.698Z"/>
                <path d="M172.779,24.29c-0.83,-2.728 -0.747,-5.086 0.249,-7.075c0.996,-1.989 2.652,-3.336 4.969,-4.041c1.518,-0.461 2.996,-0.515 4.434,-0.16c1.439,0.354 2.675,1.083 3.709,2.185c1.033,1.103 1.8,2.476 2.3,4.121c0.507,1.667 0.624,3.26 0.351,4.78c-0.272,1.52 -0.922,2.806 -1.95,3.857c-1.028,1.052 -2.255,1.795 -3.683,2.229c-1.547,0.47 -3.043,0.517 -4.489,0.14c-1.446,-0.377 -2.679,-1.116 -3.7,-2.218c-1.021,-1.103 -1.751,-2.375 -2.19,-3.818Zm2.253,-0.648c0.602,1.981 1.609,3.379 3.021,4.195c1.412,0.816 2.921,0.98 4.528,0.491c1.637,-0.498 2.809,-1.483 3.517,-2.956c0.707,-1.472 0.74,-3.266 0.096,-5.382c-0.407,-1.337 -0.988,-2.437 -1.743,-3.297c-0.756,-0.86 -1.653,-1.433 -2.691,-1.717c-1.038,-0.285 -2.098,-0.262 -3.182,0.067c-1.54,0.468 -2.704,1.4 -3.492,2.795c-0.789,1.396 -0.807,3.33 -0.054,5.804Z"/>
                <path d="M197.985,17.269c-0.168,-0.803 -0.027,-1.547 0.424,-2.232c0.451,-0.685 1.074,-1.111 1.869,-1.278c0.803,-0.169 1.549,-0.03 2.237,0.417c0.688,0.446 1.117,1.071 1.285,1.874c0.168,0.802 0.028,1.548 -0.423,2.237c-0.45,0.69 -1.076,1.118 -1.879,1.287c-0.795,0.167 -1.537,0.025 -2.227,-0.425c-0.689,-0.45 -1.117,-1.077 -1.286,-1.88Z"/>
                <path d="M213.61,17.18l2.105,-0.453c0.208,0.84 0.53,1.51 0.967,2.01c0.437,0.5 1.064,0.87 1.881,1.11c0.818,0.24 1.711,0.299 2.68,0.177c0.861,-0.108 1.604,-0.332 2.231,-0.67c0.627,-0.339 1.073,-0.751 1.338,-1.237c0.266,-0.486 0.366,-0.993 0.3,-1.52c-0.068,-0.535 -0.281,-0.983 -0.642,-1.343c-0.36,-0.36 -0.913,-0.631 -1.661,-0.813c-0.479,-0.121 -1.526,-0.271 -3.139,-0.45c-1.613,-0.179 -2.754,-0.396 -3.424,-0.65c-0.868,-0.324 -1.541,-0.777 -2.019,-1.359c-0.479,-0.581 -0.767,-1.263 -0.866,-2.046c-0.108,-0.861 0.035,-1.696 0.43,-2.505c0.394,-0.809 1.036,-1.467 1.925,-1.972c0.889,-0.506 1.907,-0.83 3.055,-0.975c1.263,-0.158 2.403,-0.095 3.419,0.191c1.016,0.285 1.834,0.791 2.453,1.516c0.618,0.726 1.01,1.586 1.174,2.581l-2.142,0.435c-0.252,-1.063 -0.747,-1.828 -1.487,-2.294c-0.74,-0.466 -1.765,-0.617 -3.075,-0.452c-1.364,0.171 -2.326,0.546 -2.888,1.125c-0.561,0.578 -0.797,1.22 -0.708,1.925c0.077,0.613 0.361,1.089 0.852,1.429c0.484,0.34 1.669,0.603 3.554,0.787c1.885,0.185 3.189,0.389 3.91,0.613c1.049,0.333 1.855,0.82 2.416,1.462c0.561,0.642 0.899,1.421 1.014,2.336c0.114,0.906 -0.039,1.794 -0.457,2.661c-0.419,0.868 -1.086,1.586 -2.003,2.154c-0.917,0.567 -1.984,0.928 -3.201,1.081c-1.542,0.194 -2.863,0.131 -3.962,-0.187c-1.099,-0.319 -2.002,-0.892 -2.709,-1.721c-0.706,-0.828 -1.147,-1.81 -1.321,-2.946Z"/>
                <path d="M235.819,20.428l-0.404,-7.267l-7.16,-9.52l2.762,-0.153l3.668,4.984c0.678,0.933 1.313,1.868 1.905,2.805c0.465,-0.926 1.033,-1.97 1.704,-3.134l3.043,-5.228l2.644,-0.147l-6.296,10.267l0.403,7.267l-2.269,0.126Z"/>
                <path d="M246.982,19.912l0.3,-17.177l5.917,0.103c1.335,0.023 2.353,0.123 3.054,0.299c0.98,0.244 1.812,0.669 2.497,1.274c0.893,0.782 1.554,1.772 1.982,2.971c0.428,1.199 0.629,2.564 0.602,4.095c-0.022,1.305 -0.195,2.458 -0.517,3.46c-0.322,1.003 -0.727,1.83 -1.215,2.482c-0.488,0.651 -1.019,1.162 -1.592,1.531c-0.573,0.369 -1.261,0.644 -2.065,0.825c-0.804,0.181 -1.726,0.263 -2.765,0.245l-6.198,-0.108Zm2.308,-1.988l3.668,0.064c1.133,0.02 2.023,-0.07 2.671,-0.27c0.648,-0.199 1.167,-0.487 1.556,-0.863c0.549,-0.53 0.981,-1.247 1.298,-2.152c0.316,-0.904 0.486,-2.005 0.509,-3.302c0.031,-1.796 -0.24,-3.182 -0.813,-4.157c-0.573,-0.975 -1.278,-1.634 -2.116,-1.977c-0.605,-0.245 -1.583,-0.379 -2.935,-0.403l-3.609,-0.063l-0.229,13.123Z"/>
                <path d="M264.268,20.337l1.552,-17.109l2.323,0.21l7.767,14.249l1.219,-13.433l2.171,0.196l-1.552,17.11l-2.323,-0.211l-7.767,-14.26l-1.219,13.445l-2.171,-0.197Z"/>
                <path d="M281.621,22.069l2.818,-16.947l12.253,2.038l-0.332,2l-10.011,-1.665l-0.863,5.191l9.375,1.559l-0.331,1.988l-9.375,-1.559l-0.959,5.769l10.404,1.73l-0.333,1.999l-12.646,-2.103Z"/>
                <path d="M302.042,25.919l1.681,-7.08l-4.154,-11.164l2.691,0.639l2.098,5.822c0.384,1.087 0.727,2.164 1.027,3.231c0.71,-0.755 1.552,-1.595 2.526,-2.52l4.405,-4.145l2.577,0.612l-8.958,8.05l-1.681,7.081l-2.212,-0.526Z"/>
                <path d="M320.875,22.298c0.254,-0.78 0.747,-1.355 1.479,-1.725c0.732,-0.37 1.485,-0.429 2.257,-0.178c0.781,0.254 1.358,0.745 1.733,1.475c0.374,0.73 0.435,1.485 0.181,2.265c-0.253,0.78 -0.747,1.357 -1.48,1.73c-0.734,0.374 -1.491,0.434 -2.271,0.18c-0.773,-0.251 -1.346,-0.743 -1.719,-1.477c-0.374,-0.733 -0.434,-1.49 -0.18,-2.27Z"/>
                <path d="M332.998,35.388l6.582,-15.87l2.1,0.871l-5.805,13.997l7.816,3.241l-0.777,1.873l-9.916,-4.112Z"/>
                <path d="M348.511,32.753c1.269,-2.553 2.948,-4.212 5.038,-4.975c2.089,-0.763 4.218,-0.606 6.387,0.472c1.42,0.706 2.532,1.682 3.335,2.927c0.803,1.245 1.202,2.624 1.197,4.135c-0.006,1.511 -0.391,3.036 -1.156,4.575c-0.775,1.56 -1.783,2.8 -3.025,3.718c-1.242,0.918 -2.597,1.407 -4.066,1.467c-1.469,0.059 -2.872,-0.243 -4.208,-0.907c-1.448,-0.72 -2.569,-1.713 -3.361,-2.98c-0.793,-1.266 -1.182,-2.65 -1.168,-4.153c0.014,-1.502 0.356,-2.928 1.027,-4.279Zm2.083,1.075c-0.921,1.854 -1.149,3.562 -0.682,5.124c0.467,1.563 1.452,2.718 2.956,3.465c1.533,0.762 3.061,0.85 4.587,0.265c1.525,-0.586 2.78,-1.869 3.764,-3.848c0.622,-1.253 0.954,-2.451 0.995,-3.595c0.041,-1.145 -0.218,-2.177 -0.778,-3.096c-0.559,-0.919 -1.346,-1.631 -2.361,-2.135c-1.441,-0.716 -2.927,-0.838 -4.458,-0.364c-1.531,0.474 -2.872,1.868 -4.023,4.184Z"/>
                <path d="M364.197,43.895l1.94,0.935c-0.352,0.79 -0.509,1.517 -0.472,2.18c0.038,0.663 0.306,1.341 0.804,2.032c0.498,0.691 1.167,1.286 2.006,1.785c0.746,0.443 1.47,0.723 2.172,0.841c0.703,0.118 1.308,0.066 1.816,-0.154c0.509,-0.221 0.898,-0.559 1.17,-1.016c0.275,-0.463 0.381,-0.948 0.318,-1.453c-0.063,-0.506 -0.333,-1.06 -0.812,-1.662c-0.304,-0.39 -1.038,-1.151 -2.201,-2.283c-1.163,-1.132 -1.931,-2.004 -2.303,-2.616c-0.486,-0.789 -0.739,-1.559 -0.759,-2.312c-0.021,-0.753 0.17,-1.469 0.573,-2.147c0.443,-0.745 1.069,-1.317 1.877,-1.713c0.809,-0.397 1.719,-0.522 2.731,-0.374c1.012,0.147 2.015,0.516 3.009,1.106c1.095,0.651 1.956,1.401 2.582,2.25c0.627,0.85 0.962,1.751 1.004,2.703c0.043,0.953 -0.176,1.872 -0.657,2.759l-1.958,-0.973c0.454,-0.993 0.532,-1.901 0.235,-2.723c-0.298,-0.822 -1.014,-1.571 -2.149,-2.245c-1.182,-0.703 -2.172,-0.998 -2.97,-0.886c-0.798,0.112 -1.379,0.473 -1.742,1.085c-0.315,0.53 -0.383,1.08 -0.204,1.65c0.172,0.567 0.946,1.501 2.321,2.804c1.375,1.304 2.278,2.265 2.709,2.885c0.624,0.907 0.961,1.786 1.009,2.638c0.049,0.851 -0.163,1.673 -0.634,2.465c-0.467,0.786 -1.132,1.393 -1.995,1.82c-0.863,0.428 -1.83,0.585 -2.903,0.47c-1.072,-0.115 -2.136,-0.485 -3.19,-1.112c-1.336,-0.794 -2.341,-1.655 -3.012,-2.581c-0.672,-0.926 -1.033,-1.933 -1.081,-3.021c-0.049,-1.088 0.206,-2.133 0.766,-3.137Z"/>
                <path d="M378.642,59.191l15.444,-10.007l1.981,1.441l-4.425,18.028l-2.094,-1.524l1.442,-5.387l-5.808,-4.227l-4.588,3.097l-1.952,-1.421Zm8.16,-2.787l4.709,3.428l1.35,-4.902c0.408,-1.491 0.779,-2.69 1.111,-3.598c-0.825,0.762 -1.718,1.465 -2.679,2.108l-4.491,2.964Z"/>
                <path d="M393.015,69.788l11.079,-13.13l1.782,1.503l-1.801,16.128l8.698,-10.309l1.666,1.406l-11.079,13.13l-1.782,-1.504l1.809,-16.136l-8.706,10.317l-1.666,-1.405Z"/>
                <path d="M416.678,81.945l1.415,-1.435l5.19,5.102l-4.477,4.538c-1.421,-0.15 -2.712,-0.482 -3.873,-0.996c-1.16,-0.514 -2.186,-1.21 -3.076,-2.088c-1.201,-1.185 -2.039,-2.519 -2.513,-4.002c-0.475,-1.483 -0.482,-2.958 -0.023,-4.425c0.46,-1.467 1.282,-2.801 2.467,-4.003c1.174,-1.19 2.519,-2.055 4.034,-2.596c1.516,-0.541 2.988,-0.601 4.416,-0.18c1.428,0.421 2.751,1.233 3.969,2.434c0.885,0.872 1.543,1.804 1.975,2.796c0.433,0.991 0.599,1.943 0.501,2.855c-0.099,0.912 -0.449,1.884 -1.049,2.916l-1.855,-1.04c0.431,-0.804 0.685,-1.518 0.764,-2.143c0.078,-0.625 -0.03,-1.294 -0.325,-2.007c-0.295,-0.714 -0.751,-1.375 -1.368,-1.984c-0.74,-0.729 -1.491,-1.248 -2.252,-1.555c-0.762,-0.306 -1.49,-0.44 -2.183,-0.399c-0.693,0.041 -1.348,0.19 -1.965,0.449c-1.057,0.449 -2.022,1.116 -2.894,2c-1.076,1.09 -1.788,2.188 -2.136,3.292c-0.349,1.105 -0.34,2.189 0.026,3.252c0.367,1.064 0.953,1.994 1.76,2.789c0.7,0.692 1.518,1.231 2.451,1.62c0.934,0.389 1.724,0.6 2.37,0.634l2.247,-2.278l-3.596,-3.546Z"/>
                <path d="M419.396,94.354l12.964,-11.273l8.151,9.373l-1.53,1.33l-6.659,-7.658l-3.971,3.453l6.237,7.172l-1.521,1.322l-6.237,-7.171l-4.412,3.837l6.921,7.959l-1.53,1.33l-8.413,-9.674Z"/>
                <path d="M429.725,106.433l13.66,-10.419l1.379,1.808l-12.048,9.189l5.131,6.727l-1.612,1.23l-6.51,-8.535Z"/>
                <path d="M437.862,117.189l14.288,-9.54l6.897,10.331l-1.686,1.126l-5.635,-8.44l-4.376,2.921l5.277,7.905l-1.676,1.119l-5.278,-7.904l-4.863,3.247l5.857,8.771l-1.687,1.126l-7.118,-10.662Z"/>
                <path d="M451.023,127.102l1.23,1.766c-0.695,0.516 -1.189,1.072 -1.482,1.668c-0.293,0.596 -0.392,1.317 -0.298,2.164c0.094,0.847 0.384,1.694 0.87,2.541c0.432,0.752 0.924,1.352 1.479,1.8c0.554,0.447 1.106,0.7 1.657,0.758c0.551,0.057 1.057,-0.046 1.518,-0.311c0.467,-0.268 0.798,-0.638 0.991,-1.109c0.194,-0.471 0.23,-1.087 0.11,-1.846c-0.074,-0.489 -0.339,-1.513 -0.796,-3.07c-0.456,-1.557 -0.697,-2.694 -0.72,-3.41c-0.036,-0.925 0.122,-1.721 0.474,-2.387c0.353,-0.666 0.871,-1.195 1.555,-1.588c0.752,-0.431 1.578,-0.621 2.477,-0.57c0.899,0.052 1.753,0.391 2.562,1.016c0.809,0.626 1.502,1.44 2.077,2.443c0.634,1.104 1.015,2.181 1.143,3.228c0.128,1.048 -0.023,1.997 -0.453,2.848c-0.431,0.85 -1.074,1.543 -1.929,2.079l-1.227,-1.809c0.884,-0.642 1.398,-1.394 1.543,-2.256c0.145,-0.863 -0.111,-1.866 -0.768,-3.012c-0.685,-1.192 -1.402,-1.936 -2.152,-2.231c-0.75,-0.294 -1.433,-0.265 -2.05,0.089c-0.535,0.307 -0.865,0.753 -0.989,1.338c-0.128,0.577 0.087,1.772 0.644,3.582c0.557,1.811 0.871,3.092 0.942,3.844c0.098,1.097 -0.041,2.028 -0.417,2.793c-0.376,0.765 -0.964,1.377 -1.764,1.836c-0.793,0.455 -1.67,0.656 -2.632,0.605c-0.962,-0.052 -1.881,-0.391 -2.759,-1.018c-0.878,-0.627 -1.622,-1.472 -2.232,-2.536c-0.774,-1.348 -1.226,-2.591 -1.356,-3.728c-0.13,-1.137 0.051,-2.191 0.543,-3.162c0.492,-0.972 1.229,-1.757 2.209,-2.355Z"/>
                <path d="M464.769,146.388c0.743,-0.348 1.5,-0.381 2.27,-0.099c0.771,0.282 1.328,0.79 1.673,1.526c0.348,0.743 0.383,1.501 0.107,2.273c-0.277,0.772 -0.787,1.332 -1.53,1.68c-0.743,0.348 -1.501,0.382 -2.275,0.102c-0.774,-0.28 -1.335,-0.792 -1.683,-1.535c-0.344,-0.736 -0.377,-1.49 -0.096,-2.264c0.28,-0.774 0.791,-1.335 1.534,-1.683Z"/>
                <path d="M463.525,164.121l16.126,-5.923l0.784,2.134l-7.997,2.937l10.939,5.071l1.062,2.893l-9.019,-4.365l-6.998,10.585l-1.034,-2.816l6.053,-8.74l-3.544,-1.694l-5.588,2.052l-0.784,-2.134Z"/>
                <path d="M470.362,183.947l6.982,-2.051l7.635,-9.144l0.779,2.654l-4.015,4.709c-0.753,0.873 -1.519,1.704 -2.296,2.495c1.007,0.241 2.154,0.556 3.44,0.943l5.785,1.77l0.746,2.541l-11.433,-3.787l-6.982,2.051l-0.641,-2.181Z"/>
                <path d="M481.463,192.277c2.79,-0.592 5.133,-0.307 7.029,0.855c1.896,1.163 3.096,2.929 3.599,5.298c0.329,1.551 0.256,3.028 -0.221,4.431c-0.477,1.403 -1.308,2.573 -2.495,3.508c-1.188,0.935 -2.622,1.581 -4.303,1.938c-1.704,0.362 -3.302,0.342 -4.793,-0.06c-1.491,-0.402 -2.716,-1.161 -3.676,-2.275c-0.959,-1.114 -1.594,-2.4 -1.904,-3.86c-0.336,-1.582 -0.254,-3.077 0.246,-4.485c0.499,-1.408 1.342,-2.573 2.527,-3.496c1.186,-0.923 2.517,-1.541 3.991,-1.854Zm0.453,2.3c-2.025,0.43 -3.505,1.313 -4.439,2.65c-0.934,1.337 -1.226,2.826 -0.877,4.469c0.355,1.674 1.236,2.926 2.643,3.758c1.406,0.831 3.191,1.017 5.353,0.558c1.368,-0.291 2.513,-0.776 3.435,-1.455c0.922,-0.679 1.57,-1.523 1.942,-2.533c0.373,-1.009 0.441,-2.068 0.206,-3.176c-0.334,-1.574 -1.163,-2.814 -2.485,-3.719c-1.323,-0.905 -3.248,-1.089 -5.778,-0.552Z"/>
                <path d="M477.691,217.297l15.011,-2.064l-0.771,-5.607l2.009,-0.277l1.855,13.491l-2.009,0.276l-0.774,-5.631l-15.011,2.064l-0.31,-2.252Z"/>
                <path d="M487.297,225.848c2.846,-0.189 5.124,0.428 6.835,1.849c1.711,1.421 2.646,3.34 2.806,5.757c0.105,1.582 -0.178,3.034 -0.85,4.355c-0.672,1.32 -1.663,2.359 -2.971,3.115c-1.308,0.756 -2.82,1.191 -4.535,1.304c-1.738,0.116 -3.317,-0.132 -4.735,-0.743c-1.419,-0.611 -2.523,-1.536 -3.314,-2.775c-0.791,-1.24 -1.235,-2.604 -1.334,-4.093c-0.107,-1.613 0.187,-3.081 0.883,-4.404c0.695,-1.322 1.695,-2.355 3.001,-3.099c1.305,-0.745 2.71,-1.167 4.214,-1.266Zm0.12,2.341c-2.066,0.137 -3.656,0.8 -4.771,1.989c-1.116,1.19 -1.618,2.623 -1.507,4.299c0.113,1.707 0.807,3.072 2.08,4.096c1.274,1.023 3.014,1.462 5.22,1.316c1.395,-0.092 2.598,-0.409 3.607,-0.95c1.01,-0.54 1.771,-1.283 2.283,-2.23c0.513,-0.946 0.732,-1.984 0.657,-3.114c-0.106,-1.606 -0.749,-2.951 -1.929,-4.036c-1.18,-1.084 -3.06,-1.541 -5.64,-1.37Z"/>
                <path d="M488.424,251.978c0.82,0.017 1.514,0.322 2.08,0.916c0.566,0.594 0.841,1.297 0.824,2.109c-0.017,0.82 -0.32,1.515 -0.91,2.085c-0.59,0.571 -1.295,0.847 -2.115,0.831c-0.82,-0.017 -1.515,-0.323 -2.085,-0.916c-0.57,-0.594 -0.847,-1.301 -0.83,-2.121c0.016,-0.813 0.322,-1.504 0.915,-2.074c0.594,-0.57 1.301,-0.846 2.121,-0.83Z"/>
                <path d="M487.861,267.682c2.835,0.306 4.972,1.307 6.412,3.003c1.439,1.695 2.029,3.747 1.769,6.155c-0.171,1.577 -0.701,2.958 -1.591,4.142c-0.891,1.185 -2.045,2.036 -3.465,2.555c-1.419,0.518 -2.983,0.685 -4.692,0.501c-1.732,-0.188 -3.244,-0.705 -4.535,-1.551c-1.292,-0.847 -2.22,-1.949 -2.784,-3.307c-0.565,-1.358 -0.767,-2.778 -0.606,-4.262c0.173,-1.608 0.717,-3.002 1.63,-4.185c0.914,-1.182 2.078,-2.026 3.492,-2.534c1.414,-0.507 2.871,-0.679 4.37,-0.517Zm-0.287,2.326c-2.058,-0.222 -3.739,0.156 -5.043,1.135c-1.304,0.978 -2.047,2.303 -2.227,3.973c-0.184,1.701 0.263,3.165 1.34,4.394c1.078,1.228 2.715,1.961 4.913,2.199c1.391,0.15 2.63,0.046 3.717,-0.312c1.088,-0.358 1.966,-0.958 2.635,-1.801c0.669,-0.844 1.064,-1.828 1.186,-2.955c0.173,-1.6 -0.228,-3.036 -1.203,-4.308c-0.974,-1.272 -2.747,-2.047 -5.318,-2.325Z"/>
                <path d="M482.842,286.251l-0.205,2.143c-0.864,-0.056 -1.6,0.048 -2.209,0.313c-0.609,0.265 -1.152,0.751 -1.628,1.457c-0.476,0.707 -0.803,1.54 -0.98,2.501c-0.158,0.852 -0.17,1.629 -0.037,2.329c0.133,0.699 0.391,1.25 0.774,1.65c0.382,0.4 0.835,0.649 1.358,0.745c0.53,0.098 1.021,0.03 1.473,-0.204c0.453,-0.234 0.879,-0.68 1.278,-1.337c0.26,-0.421 0.72,-1.373 1.38,-2.856c0.659,-1.483 1.211,-2.505 1.656,-3.066c0.572,-0.729 1.207,-1.233 1.907,-1.513c0.699,-0.28 1.436,-0.348 2.212,-0.205c0.853,0.157 1.605,0.547 2.257,1.168c0.652,0.621 1.084,1.431 1.297,2.432c0.212,1 0.214,2.069 0.004,3.206c-0.232,1.252 -0.637,2.32 -1.217,3.202c-0.58,0.882 -1.31,1.507 -2.188,1.877c-0.879,0.37 -1.818,0.483 -2.816,0.339l0.235,-2.174c1.089,0.082 1.968,-0.159 2.636,-0.722c0.668,-0.564 1.122,-1.495 1.362,-2.794c0.25,-1.352 0.184,-2.383 -0.197,-3.093c-0.382,-0.71 -0.922,-1.129 -1.621,-1.258c-0.607,-0.113 -1.147,0.014 -1.619,0.38c-0.472,0.358 -1.081,1.407 -1.827,3.148c-0.747,1.741 -1.336,2.921 -1.768,3.54c-0.635,0.9 -1.344,1.52 -2.126,1.86c-0.781,0.34 -1.626,0.427 -2.532,0.259c-0.899,-0.166 -1.698,-0.579 -2.399,-1.241c-0.7,-0.662 -1.181,-1.515 -1.445,-2.561c-0.264,-1.046 -0.284,-2.172 -0.061,-3.378c0.282,-1.529 0.741,-2.769 1.378,-3.72c0.636,-0.951 1.456,-1.637 2.46,-2.06c1.003,-0.422 2.073,-0.544 3.208,-0.367Z"/>
                <path d="M474.711,299.884l14.981,10.689l-0.613,2.371l-18.393,2.506l0.648,-2.507l5.539,-0.637l1.799,-6.955l-4.566,-3.129l0.605,-2.338Zm5.589,6.566l-1.458,5.638l5.055,-0.544c1.536,-0.168 2.787,-0.264 3.754,-0.288c-1.012,-0.488 -1.993,-1.061 -2.945,-1.718l-4.406,-3.088Z"/>
                <path d="M470.159,317.093l16.27,5.517l-0.73,2.153l-8.068,-2.736l5.328,10.816l-0.99,2.918l-4.277,-9.06l-12.094,3.843l0.963,-2.841l10.2,-3.001l-1.694,-3.544l-5.638,-1.912l0.73,-2.153Z"/>
                <path d="M465.621,330.571l13.339,12.678l-0.939,2.263l-18.562,-0.09l0.992,-2.392l5.574,0.143l2.753,-6.635l-4.083,-3.737l0.926,-2.23Zm4.616,7.283l-2.232,5.379l5.081,0.168c1.545,0.048 2.797,0.128 3.758,0.239c-0.934,-0.624 -1.826,-1.328 -2.676,-2.112l-3.931,-3.674Z"/>
                <path d="M464.025,355.136c0.732,0.372 1.223,0.948 1.475,1.729c0.252,0.78 0.193,1.533 -0.175,2.257c-0.371,0.732 -0.947,1.226 -1.726,1.483c-0.779,0.257 -1.534,0.199 -2.265,-0.172c-0.732,-0.372 -1.225,-0.949 -1.48,-1.732c-0.255,-0.782 -0.197,-1.539 0.175,-2.27c0.368,-0.725 0.943,-1.214 1.726,-1.47c0.782,-0.255 1.539,-0.197 2.27,0.175Z"/>
                <path d="M452.469,359.336l14.867,8.609l-3.247,5.608c-0.572,0.987 -1.056,1.714 -1.452,2.18c-0.544,0.659 -1.13,1.137 -1.757,1.433c-0.627,0.296 -1.336,0.416 -2.125,0.36c-0.79,-0.055 -1.553,-0.296 -2.29,-0.723c-1.264,-0.732 -2.101,-1.754 -2.511,-3.065c-0.409,-1.312 -0.005,-3.019 1.212,-5.121l2.208,-3.813l-6.044,-3.5l1.139,-1.968Zm6.66,6.484l-2.226,3.843c-0.736,1.271 -1.022,2.311 -0.858,3.119c0.164,0.808 0.675,1.461 1.534,1.958c0.622,0.36 1.245,0.511 1.87,0.453c0.625,-0.058 1.157,-0.303 1.597,-0.734c0.279,-0.281 0.678,-0.867 1.194,-1.759l2.203,-3.803l-5.314,-3.077Z"/>
                <path d="M451.68,377.31c2.355,1.607 3.768,3.497 4.238,5.671c0.47,2.174 0.022,4.262 -1.342,6.262c-0.894,1.311 -2.013,2.278 -3.356,2.903c-1.344,0.625 -2.764,0.832 -4.26,0.62c-1.496,-0.212 -2.954,-0.803 -4.374,-1.771c-1.439,-0.982 -2.529,-2.151 -3.269,-3.506c-0.739,-1.356 -1.038,-2.766 -0.896,-4.229c0.143,-1.463 0.634,-2.811 1.475,-4.044c0.911,-1.336 2.049,-2.31 3.412,-2.922c1.363,-0.611 2.787,-0.807 4.274,-0.588c1.486,0.22 2.852,0.754 4.098,1.604Zm-1.35,1.916c-1.711,-1.166 -3.371,-1.626 -4.983,-1.377c-1.612,0.248 -2.891,1.066 -3.837,2.454c-0.964,1.413 -1.261,2.915 -0.89,4.506c0.371,1.592 1.47,3.01 3.296,4.256c1.155,0.788 2.297,1.281 3.425,1.478c1.128,0.197 2.186,0.082 3.173,-0.346c0.987,-0.429 1.8,-1.111 2.438,-2.047c0.907,-1.329 1.231,-2.785 0.971,-4.366c-0.259,-1.581 -1.457,-3.101 -3.593,-4.558Z"/>
                <path d="M433.712,388.54l13.361,10.8l-4.788,5.924c-0.963,1.19 -1.815,1.999 -2.556,2.425c-0.74,0.425 -1.565,0.578 -2.472,0.457c-0.907,-0.12 -1.728,-0.477 -2.463,-1.072c-0.948,-0.766 -1.499,-1.719 -1.653,-2.858c-0.154,-1.139 0.199,-2.421 1.059,-3.845c-0.603,0.286 -1.112,0.462 -1.527,0.529c-0.892,0.133 -1.879,0.143 -2.964,0.03l-5.514,-0.615l1.797,-2.224l4.209,0.479c1.219,0.132 2.177,0.203 2.872,0.212c0.696,0.01 1.235,-0.049 1.619,-0.176c0.384,-0.126 0.72,-0.299 1.007,-0.519c0.205,-0.166 0.491,-0.477 0.86,-0.933l1.657,-2.05l-5.933,-4.796l1.429,-1.768Zm6.035,7.801l-3.072,3.801c-0.653,0.808 -1.08,1.507 -1.281,2.098c-0.201,0.591 -0.214,1.153 -0.038,1.687c0.176,0.534 0.48,0.976 0.911,1.325c0.632,0.51 1.337,0.701 2.115,0.571c0.778,-0.13 1.567,-0.69 2.367,-1.68l3.419,-4.229l-4.421,-3.573Z"/>
                <path d="M420.067,404.786l11.151,10.259l3.832,-4.166l1.492,1.373l-9.219,10.021l-1.492,-1.372l3.848,-4.183l-11.152,-10.258l1.54,-1.674Z"/>
                <path d="M413.056,412.236l11.894,12.396l-1.64,1.574l-10.491,-10.933l-6.105,5.858l-1.404,-1.463l7.746,-7.432Z"/>
                <path d="M404.78,420.258l6.081,17.369l-1.868,1.584l-16.475,-8.553l1.975,-1.675l4.894,2.671l5.478,-4.646l-1.926,-5.189l1.841,-1.561Zm0.783,8.586l-4.442,3.768l4.445,2.468c1.352,0.748 2.43,1.391 3.234,1.928c-0.546,-0.981 -1.018,-2.015 -1.416,-3.101l-1.821,-5.063Z"/>
                <path d="M391.058,431.751l10.144,13.866l-1.883,1.377l-15.246,-5.559l7.964,10.886l-1.76,1.287l-10.143,-13.865l1.882,-1.377l15.254,5.568l-7.971,-10.896l1.759,-1.287Z"/>
                <path d="M376.961,441.898l9.051,14.602l-5.03,3.118c-1.136,0.704 -2.046,1.171 -2.73,1.402c-0.956,0.326 -1.886,0.42 -2.79,0.282c-1.173,-0.174 -2.265,-0.648 -3.274,-1.424c-1.01,-0.776 -1.918,-1.815 -2.724,-3.117c-0.688,-1.109 -1.167,-2.172 -1.439,-3.189c-0.272,-1.017 -0.38,-1.932 -0.322,-2.744c0.057,-0.812 0.226,-1.528 0.508,-2.149c0.282,-0.62 0.712,-1.224 1.29,-1.812c0.577,-0.588 1.308,-1.156 2.191,-1.703l5.269,-3.266Zm-0.864,2.921l-3.118,1.932c-0.963,0.597 -1.663,1.155 -2.099,1.674c-0.437,0.519 -0.717,1.041 -0.841,1.569c-0.174,0.742 -0.149,1.579 0.075,2.511c0.223,0.932 0.677,1.949 1.36,3.051c0.947,1.528 1.925,2.546 2.935,3.055c1.009,0.51 1.959,0.682 2.849,0.516c0.642,-0.122 1.537,-0.539 2.685,-1.251l3.068,-1.901l-6.914,-11.156Z"/>
                <path d="M360.593,461.25c0.372,0.731 0.43,1.486 0.173,2.265c-0.256,0.78 -0.746,1.354 -1.47,1.722c-0.731,0.372 -1.487,0.433 -2.268,0.182c-0.781,-0.251 -1.358,-0.742 -1.73,-1.473c-0.372,-0.731 -0.431,-1.488 -0.176,-2.271c0.254,-0.783 0.747,-1.36 1.478,-1.732c0.724,-0.369 1.477,-0.426 2.26,-0.172c0.783,0.255 1.36,0.748 1.733,1.479Z"/>
                <path d="M345.527,465.42l-1.914,0.987c-0.42,-0.757 -0.906,-1.319 -1.458,-1.688c-0.552,-0.369 -1.254,-0.563 -2.106,-0.581c-0.852,-0.018 -1.73,0.159 -2.633,0.529c-0.802,0.329 -1.462,0.739 -1.979,1.229c-0.516,0.491 -0.839,1.005 -0.969,1.544c-0.129,0.538 -0.093,1.054 0.108,1.545c0.205,0.499 0.528,0.875 0.969,1.129c0.442,0.254 1.048,0.371 1.816,0.351c0.495,-0.009 1.544,-0.137 3.148,-0.385c1.604,-0.248 2.762,-0.337 3.475,-0.266c0.922,0.086 1.69,0.348 2.304,0.784c0.614,0.437 1.07,1.02 1.37,1.75c0.329,0.802 0.409,1.646 0.239,2.53c-0.169,0.884 -0.617,1.686 -1.344,2.406c-0.726,0.72 -1.624,1.3 -2.694,1.738c-1.178,0.484 -2.295,0.72 -3.351,0.709c-1.055,-0.01 -1.976,-0.285 -2.763,-0.824c-0.787,-0.538 -1.389,-1.267 -1.808,-2.184l1.955,-0.979c0.52,0.96 1.198,1.569 2.034,1.826c0.836,0.257 1.864,0.135 3.086,-0.366c1.272,-0.522 2.104,-1.135 2.494,-1.84c0.391,-0.705 0.452,-1.386 0.182,-2.044c-0.234,-0.571 -0.633,-0.956 -1.196,-1.156c-0.556,-0.203 -1.768,-0.147 -3.636,0.167c-1.868,0.315 -3.18,0.458 -3.934,0.429c-1.1,-0.047 -2.005,-0.307 -2.714,-0.781c-0.709,-0.473 -1.239,-1.136 -1.588,-1.989c-0.347,-0.846 -0.432,-1.742 -0.254,-2.689c0.177,-0.947 0.635,-1.814 1.371,-2.601c0.737,-0.788 1.673,-1.414 2.808,-1.88c1.438,-0.589 2.73,-0.874 3.874,-0.853c1.144,0.02 2.165,0.338 3.063,0.954c0.899,0.615 1.58,1.448 2.045,2.499Z"/>
                <path d="M327.471,466.608l5.711,16.203l-2.144,0.756l-5.711,-16.203l2.144,-0.756Z"/>
                <path d="M321.554,468.739l4.858,16.479l-2.237,0.659l-12.47,-10.386l3.814,12.938l-2.09,0.616l-4.858,-16.479l2.237,-0.659l12.472,10.397l-3.817,-12.949l2.091,-0.616Z"/>
                <path d="M298.425,481.772l0.417,1.972l-7.118,1.515l-1.317,-6.237c0.909,-1.102 1.898,-1.996 2.967,-2.681c1.069,-0.685 2.215,-1.156 3.438,-1.414c1.651,-0.349 3.226,-0.312 4.724,0.11c1.499,0.422 2.733,1.229 3.704,2.422c0.97,1.192 1.63,2.614 1.978,4.265c0.346,1.636 0.326,3.235 -0.059,4.797c-0.384,1.563 -1.146,2.823 -2.285,3.782c-1.139,0.96 -2.545,1.616 -4.219,1.969c-1.216,0.257 -2.356,0.292 -3.421,0.106c-1.066,-0.186 -1.952,-0.572 -2.658,-1.157c-0.707,-0.586 -1.325,-1.413 -1.854,-2.483l1.89,-0.974c0.433,0.802 0.889,1.409 1.367,1.819c0.477,0.41 1.095,0.689 1.853,0.836c0.758,0.148 1.561,0.132 2.409,-0.047c1.017,-0.215 1.863,-0.556 2.539,-1.022c0.676,-0.466 1.189,-0.999 1.537,-1.6c0.348,-0.6 0.585,-1.229 0.709,-1.886c0.209,-1.13 0.185,-2.303 -0.072,-3.518c-0.316,-1.499 -0.839,-2.698 -1.568,-3.598c-0.729,-0.9 -1.638,-1.49 -2.728,-1.771c-1.089,-0.281 -2.188,-0.305 -3.296,-0.071c-0.963,0.204 -1.864,0.588 -2.703,1.152c-0.839,0.565 -1.452,1.107 -1.837,1.628l0.661,3.13l4.942,-1.044Z"/>
                <path d="M288.458,476.936l-4.28,17.898l-2.428,0.322l-9.23,-16.106l2.567,-0.34l2.671,4.894l7.121,-0.945l1.186,-5.406l2.393,-0.317Zm-3.986,7.645l-5.773,0.766l2.403,4.481c0.732,1.361 1.291,2.484 1.677,3.371c0.072,-1.121 0.234,-2.246 0.486,-3.374l1.207,-5.244Z"/>
                <path d="M270.666,479.176l1.04,17.148l-6.468,0.392c-1.139,0.069 -2.012,0.068 -2.619,-0.005c-0.85,-0.089 -1.572,-0.314 -2.166,-0.673c-0.593,-0.359 -1.084,-0.883 -1.474,-1.572c-0.391,-0.688 -0.611,-1.458 -0.663,-2.308c-0.088,-1.458 0.301,-2.72 1.167,-3.786c0.867,-1.066 2.513,-1.673 4.938,-1.82l4.398,-0.267l-0.423,-6.972l2.27,-0.137Zm-1.724,9.132l-4.433,0.269c-1.466,0.089 -2.491,0.426 -3.074,1.009c-0.582,0.583 -0.844,1.37 -0.784,2.36c0.044,0.718 0.262,1.321 0.656,1.81c0.394,0.488 0.892,0.797 1.494,0.924c0.389,0.079 1.097,0.086 2.127,0.024l4.386,-0.266l-0.372,-6.13Z"/>
                <path d="M255.281,488.449c-0.034,2.851 -0.827,5.074 -2.377,6.669c-1.551,1.594 -3.537,2.377 -5.959,2.348c-1.586,-0.019 -3.011,-0.415 -4.275,-1.189c-1.264,-0.773 -2.222,-1.841 -2.873,-3.205c-0.652,-1.363 -0.967,-2.904 -0.947,-4.623c0.022,-1.742 0.392,-3.296 1.112,-4.662c0.719,-1.367 1.728,-2.396 3.025,-3.087c1.298,-0.691 2.693,-1.028 4.185,-1.01c1.617,0.019 3.057,0.428 4.321,1.224c1.264,0.797 2.216,1.875 2.856,3.234c0.64,1.36 0.951,2.793 0.932,4.301Zm-2.343,-0.064c0.025,-2.07 -0.512,-3.707 -1.61,-4.912c-1.099,-1.205 -2.488,-1.817 -4.168,-1.838c-1.711,-0.02 -3.126,0.564 -4.246,1.754c-1.12,1.189 -1.693,2.89 -1.72,5.1c-0.017,1.399 0.205,2.622 0.665,3.671c0.46,1.048 1.141,1.865 2.044,2.451c0.903,0.585 1.921,0.884 3.054,0.898c1.609,0.019 3.001,-0.517 4.174,-1.608c1.174,-1.091 1.776,-2.93 1.807,-5.516Z"/>
                <path d="M235.985,479.703l-1.593,17.106l-7.584,-0.706c-1.525,-0.142 -2.67,-0.404 -3.434,-0.785c-0.765,-0.381 -1.349,-0.983 -1.751,-1.805c-0.402,-0.822 -0.559,-1.703 -0.471,-2.645c0.113,-1.213 0.601,-2.199 1.464,-2.959c0.863,-0.759 2.126,-1.175 3.788,-1.248c-0.572,-0.343 -1.001,-0.669 -1.286,-0.978c-0.604,-0.669 -1.16,-1.486 -1.667,-2.451l-2.541,-4.932l2.847,0.265l1.932,3.769c0.566,1.089 1.037,1.925 1.414,2.509c0.377,0.585 0.725,1.002 1.043,1.251c0.318,0.249 0.648,0.433 0.99,0.551c0.252,0.078 0.669,0.145 1.253,0.199l2.625,0.245l0.707,-7.596l2.264,0.21Zm-3.153,9.346l-4.866,-0.453c-1.035,-0.097 -1.854,-0.065 -2.457,0.095c-0.603,0.159 -1.079,0.46 -1.426,0.903c-0.347,0.442 -0.546,0.939 -0.598,1.492c-0.075,0.809 0.157,1.501 0.695,2.077c0.539,0.576 1.443,0.923 2.711,1.041l5.414,0.504l0.527,-5.659Z"/>
                <path d="M218.736,477.979l-2.791,16.952l-12.257,-2.019l0.329,-2l10.014,1.649l0.855,-5.192l-9.378,-1.544l0.328,-1.989l9.377,1.544l0.951,-5.77l-10.407,-1.713l0.329,-2.001l12.65,2.083Z"/>
                <path d="M195.03,482.052c-0.199,0.796 -0.65,1.404 -1.355,1.824c-0.705,0.42 -1.451,0.531 -2.24,0.335c-0.796,-0.199 -1.406,-0.649 -1.831,-1.351c-0.424,-0.702 -0.537,-1.451 -0.338,-2.247c0.198,-0.796 0.65,-1.406 1.356,-1.829c0.706,-0.424 1.457,-0.537 2.252,-0.338c0.789,0.197 1.395,0.648 1.818,1.354c0.424,0.706 0.537,1.456 0.338,2.252Z"/>
                <path d="M177.787,468.42l-2.323,6.897l3.112,11.498l-2.621,-0.883l-1.555,-5.99c-0.283,-1.118 -0.525,-2.222 -0.727,-3.312c-0.775,0.687 -1.691,1.446 -2.746,2.277l-4.767,3.724l-2.509,-0.846l9.658,-7.194l2.324,-6.897l2.154,0.726Z"/>
                <path d="M164.601,472.74c-1.114,2.625 -2.691,4.381 -4.731,5.267c-2.039,0.887 -4.174,0.858 -6.403,-0.089c-1.46,-0.619 -2.628,-1.526 -3.505,-2.722c-0.876,-1.195 -1.356,-2.547 -1.442,-4.055c-0.085,-1.509 0.208,-3.054 0.879,-4.637c0.681,-1.603 1.614,-2.901 2.798,-3.892c1.184,-0.991 2.508,-1.56 3.971,-1.707c1.463,-0.147 2.881,0.07 4.254,0.653c1.489,0.632 2.667,1.556 3.534,2.773c0.867,1.217 1.339,2.575 1.415,4.076c0.076,1.5 -0.181,2.945 -0.77,4.333Zm-2.144,-0.948c0.809,-1.906 0.934,-3.625 0.375,-5.156c-0.56,-1.532 -1.612,-2.626 -3.159,-3.282c-1.575,-0.669 -3.106,-0.665 -4.594,0.011c-1.487,0.675 -2.663,2.031 -3.527,4.066c-0.546,1.287 -0.805,2.503 -0.778,3.648c0.028,1.145 0.348,2.159 0.962,3.043c0.613,0.884 1.442,1.548 2.484,1.99c1.482,0.629 2.973,0.661 4.472,0.097c1.5,-0.564 2.755,-2.037 3.765,-4.417Z"/>
                <path d="M150.196,457.366l-7.942,15.234l-2.016,-1.052l3.939,-7.554l-11.504,3.61l-2.733,-1.425l9.609,-2.84l-1.947,-12.539l2.66,1.387l1.404,10.538l3.762,-1.131l2.752,-5.279l2.016,1.051Z"/>
                <path d="M132.118,457.347c-1.497,2.427 -3.321,3.925 -5.471,4.494c-2.15,0.57 -4.256,0.219 -6.317,-1.052c-1.35,-0.833 -2.368,-1.906 -3.054,-3.219c-0.686,-1.314 -0.958,-2.723 -0.815,-4.227c0.143,-1.504 0.666,-2.988 1.568,-4.451c0.914,-1.483 2.032,-2.625 3.352,-3.426c1.32,-0.801 2.714,-1.165 4.183,-1.09c1.468,0.075 2.837,0.504 4.107,1.287c1.377,0.849 2.402,1.94 3.076,3.274c0.673,1.333 0.935,2.747 0.784,4.242c-0.151,1.495 -0.622,2.884 -1.413,4.168Zm-1.977,-1.261c1.087,-1.762 1.469,-3.442 1.147,-5.041c-0.322,-1.598 -1.198,-2.838 -2.628,-3.72c-1.456,-0.898 -2.971,-1.125 -4.543,-0.681c-1.573,0.444 -2.939,1.606 -4.1,3.488c-0.734,1.191 -1.173,2.354 -1.318,3.49c-0.145,1.135 0.019,2.187 0.492,3.153c0.473,0.967 1.192,1.747 2.156,2.342c1.37,0.844 2.839,1.101 4.407,0.769c1.567,-0.332 3.03,-1.599 4.387,-3.8Z"/>
                <path d="M120.056,439.872l-10.128,13.877l-1.836,-1.34l4.159,-5.698l-7.213,-5.265l-4.159,5.699l-1.836,-1.341l10.127,-13.876l1.837,1.34l-4.774,6.541l7.213,5.264l4.774,-6.541l1.836,1.34Z"/>
                <path d="M107.651,430.792l-16.136,8.848l-1.869,-1.582l5.734,-17.655l1.977,1.673l-1.833,5.266l5.483,4.642l4.802,-2.752l1.842,1.56Zm-8.342,2.181l-4.445,-3.763l-1.706,4.789c-0.516,1.457 -0.973,2.626 -1.371,3.507c0.878,-0.7 1.821,-1.335 2.826,-1.906l4.696,-2.627Z"/>
                <path d="M94.08,419.22l-12.085,12.211l-2.432,-2.407l5.666,-11.506c0.531,-1.069 0.932,-1.864 1.206,-2.385c-0.579,0.307 -1.441,0.745 -2.587,1.315l-11.332,5.603l-2.174,-2.152l12.085,-12.211l1.557,1.542l-10.114,10.22l13.663,-6.709l1.457,1.443l-6.755,13.89l10.287,-10.395l1.558,1.541Z"/>
                <path d="M81.202,406.369l-17.311,6.244l-1.602,-1.853l8.399,-16.554l1.694,1.959l-2.626,4.919l4.698,5.434l5.17,-1.975l1.578,1.826Zm-8.578,0.864l-3.809,-4.407l-2.427,4.468c-0.736,1.359 -1.369,2.443 -1.898,3.252c0.976,-0.555 2.006,-1.037 3.088,-1.445l5.046,-1.868Z"/>
                <path d="M59.972,393.963c-0.66,0.487 -1.395,0.669 -2.206,0.545c-0.811,-0.124 -1.458,-0.513 -1.941,-1.166c-0.488,-0.66 -0.672,-1.395 -0.554,-2.207c0.119,-0.812 0.508,-1.461 1.168,-1.949c0.659,-0.488 1.396,-0.671 2.21,-0.549c0.814,0.122 1.465,0.512 1.953,1.172c0.483,0.653 0.664,1.387 0.542,2.201c-0.122,0.814 -0.513,1.465 -1.172,1.953Z"/>
                <path d="M53.37,379.793l-1.291,-1.723c0.677,-0.54 1.151,-1.112 1.423,-1.718c0.272,-0.606 0.347,-1.33 0.224,-2.173c-0.124,-0.843 -0.443,-1.68 -0.958,-2.509c-0.458,-0.737 -0.971,-1.319 -1.54,-1.748c-0.57,-0.428 -1.131,-0.661 -1.683,-0.7c-0.553,-0.038 -1.055,0.083 -1.506,0.363c-0.458,0.284 -0.776,0.665 -0.953,1.143c-0.177,0.478 -0.192,1.094 -0.045,1.849c0.091,0.486 0.391,1.5 0.902,3.04c0.51,1.541 0.789,2.669 0.838,3.383c0.067,0.924 -0.063,1.725 -0.392,2.402c-0.329,0.678 -0.828,1.225 -1.498,1.641c-0.737,0.457 -1.556,0.676 -2.456,0.655c-0.9,-0.02 -1.765,-0.329 -2.596,-0.926c-0.83,-0.597 -1.55,-1.387 -2.16,-2.369c-0.672,-1.082 -1.09,-2.144 -1.255,-3.187c-0.164,-1.043 -0.046,-1.996 0.355,-2.861c0.401,-0.866 1.019,-1.58 1.855,-2.145l1.289,1.765c-0.861,0.672 -1.349,1.442 -1.464,2.309c-0.114,0.866 0.176,1.861 0.873,2.982c0.725,1.168 1.468,1.887 2.228,2.155c0.759,0.269 1.441,0.216 2.045,-0.159c0.525,-0.326 0.839,-0.783 0.942,-1.371c0.108,-0.582 -0.148,-1.768 -0.767,-3.558c-0.62,-1.791 -0.978,-3.06 -1.075,-3.809c-0.136,-1.093 -0.029,-2.028 0.32,-2.806c0.349,-0.778 0.915,-1.41 1.699,-1.896c0.776,-0.482 1.646,-0.714 2.609,-0.696c0.963,0.018 1.894,0.325 2.793,0.921c0.899,0.596 1.672,1.416 2.319,2.458c0.82,1.32 1.314,2.547 1.484,3.678c0.169,1.132 0.024,2.192 -0.434,3.18c-0.458,0.988 -1.166,1.798 -2.125,2.43Z"/>
                <path d="M49.285,362.506l-15.205,7.998l-5.783,-10.994l1.795,-0.944l4.724,8.982l4.657,-2.449l-4.425,-8.412l1.784,-0.938l4.425,8.411l5.175,-2.722l-4.91,-9.334l1.795,-0.944l5.968,11.346Z"/>
                <path d="M42.721,350.056l-18.381,0.902l-0.989,-2.24l12.877,-13.37l1.046,2.369l-3.95,3.934l2.901,6.572l5.521,-0.375l0.975,2.208Zm-8.455,-1.685l-2.352,-5.329l-3.629,3.562c-1.101,1.084 -2.024,1.935 -2.767,2.554c1.096,-0.245 2.222,-0.405 3.376,-0.479l5.372,-0.308Z"/>
                <path d="M34.762,331.196l-14.201,5.284l1.974,5.305l-1.9,0.707l-4.749,-12.762l1.9,-0.707l1.982,5.327l14.201,-5.285l0.793,2.131Z"/>
                <path d="M30.019,317.307l-14.512,4.361l1.629,5.421l-1.941,0.583l-3.919,-13.041l1.941,-0.584l1.636,5.444l14.511,-4.361l0.655,2.177Z"/>
                ]<path d="M27.191,307.491l-16.732,3.894l-0.516,-2.214l14.758,-3.435l-1.918,-8.24l1.975,-0.46l2.433,10.455Z"/>
                <path d="M24.19,294.356l-16.946,2.825l-2.043,-12.253l2,-0.334l1.669,10.011l5.19,-0.865l-1.563,-9.375l1.988,-0.331l1.563,9.374l5.768,-0.961l-1.734,-10.404l2,-0.333l2.108,12.646Z"/>
                <path d="M12.687,273.22c-0.818,0.069 -1.539,-0.162 -2.164,-0.693c-0.625,-0.532 -0.972,-1.202 -1.04,-2.012c-0.069,-0.817 0.16,-1.54 0.687,-2.169c0.527,-0.628 1.2,-0.977 2.017,-1.046c0.818,-0.069 1.541,0.162 2.17,0.693c0.628,0.531 0.977,1.206 1.046,2.023c0.069,0.81 -0.163,1.529 -0.694,2.158c-0.531,0.629 -1.205,0.978 -2.022,1.046Z"/>
            </g>
        </svg>
    );
}